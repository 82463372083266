import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function CategoryCard(props) {
  const { image, alt_text, categoryName, link } = props;
  return (
    <Link
      to={link}
      style={{
        color: " #36454F",
        textDecoration: "none",
      }}
    >
      <Grid item style={{ padding: "7px" }}>
        <img src={image} alt={alt_text} style={{ height: "150px" }}></img>
        <Typography
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            inlineSize: "150px",
          }}
        >
          {categoryName}
        </Typography>
      </Grid>
    </Link>
  );
}

export default function CategoryList(props) {
  const { data } = props;
  return (
    <>
      <div>
        <Grid container md={12}>
          {data.map((category) => {
            return (
              <div>
                <CategoryCard
                  key={category.id}
                  image={category.image}
                  categoryName={category.categoryName}
                  link={category.link}
                />
              </div>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

function CategoryCardMobile(props) {
  const { image, alt_text, categoryName, link } = props;
  return (
    <Link
      to={link}
      style={{
        color: " #36454F",
        textDecoration: "none",
      }}
    >
      <img src={image} alt={alt_text} style={{ height: "110px" }}></img>
      <Typography
        style={{
          textAlign: "center",
          overflowWrap: "break-word",
          inlineSize: "110px",
          fontSize: "14px",
        }}
      >
        {categoryName}
      </Typography>
    </Link>
  );
}

export function CategoryListMobile(props) {
  const { data } = props;
  return (
    <>
      <Grid container xs={12} spacing={0.5}>
        {data.map((category) => {
          return (
            <Grid item >
              <div>
                <CategoryCardMobile
                  key={category.id}
                  image={category.image}
                  categoryName={category.categoryName}
                  link={category.link}
                />
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}