import { ProductCategoryPages } from "../../../../CategoryPage";
import { computerParts } from "../../../../data/Electronics/laptops/ComputerPartsAndComponents";
import SubCategoryPages from "../../SubCategoryPages";

export default function ComputerPartsAndComponents() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={[
        "Home",
        "Electronics",
        "Computer Parts and Components",
      ]}
      category={
        <SubCategoryPages
          data={computerParts}
          title={"Shop by Brand"}
          docTitle={"PC Desktops & All-in-One Computer for sale | QnC"}
          categoryItems={computerParts}
        />
      }
    />
  );
}
