export const mobilePhoneCategories = [
  {
    id: 1,
    image: "images/electronics/mobile-phones/brands/iphones.webp",
    alt_text: "deals",
    categoryName: "iPhones",
    link: "/apple-iphones",
  },
  {
    id: 2,
    image: "images/electronics/mobile-phones/brands/samsung.webp",
    alt_text: "samsung",
    categoryName: "Samsung",
    link: "/samsung-phones",
  },
  {
    id: 11,
    image: "images/electronics/mobile-phones/brands/Techno.webp",
    alt_text: "samsung",
    categoryName: "Techno",
    link: "/techno-phones",
  },
  {
    id: 12,
    image: "images/electronics/mobile-phones/brands/itel.png",
    alt_text: "itel",
    categoryName: "itel",
    link: "/itel-phones",
  },
  {
    id: 13,
    image: "images/electronics/mobile-phones/brands/Infinix.png",
    alt_text: "infinix",
    categoryName: "Infinix",
    link: "/infinix-phones",
  },
  {
    id: 13,
    image: "images/electronics/mobile-phones/brands/Alcatel.png",
    alt_text: "Alcatel",
    categoryName: "Alcatel",
    link: "/alcatel-phones",
  },
  {
    id: 3,
    image: "images/electronics/mobile-phones/brands/google.webp",
    alt_text: "google",
    categoryName: "Google",
    link: "/google-phones",
  },
  {
    id: 4,
    image: "images/electronics/mobile-phones/brands/Huawei.webp",
    alt_text: "huawei",
    categoryName: "Huawei",
    link: "/huawei-phones",
  },
  {
    id: 6,
    image: "images/electronics/mobile-phones/brands/LG.webp",
    alt_text: "lg",
    categoryName: "LG",
    link: "/lg-phones",
  },
  {
    id: 7,
    image: "images/electronics/mobile-phones/brands/HTC.webp",
    alt_text: "htc",
    categoryName: "HTC",
    link: "/htc-phones",
  },
  {
    id: 8,
    image: "images/electronics/mobile-phones/brands/OnePlus.webp",
    alt_text: "One Plus",
    categoryName: "One Plus",
    link: "/one-plus-phones",
  },
  {
    id: 9,
    image: "images/electronics/mobile-phones/brands/Blackberry.webp",
    alt_text: "Blackberry",
    categoryName: "Blackberry",
    link: "/blackberry-phones",
  },
  {
    id: 10,
    image: "images/electronics/mobile-phones/brands/Motorolla.webp",
    alt_text: "Motorolla",
    categoryName: "Motorolla",
    link: "/motorolla-phones",
  },
  {
    id: 5,
    image: "images/electronics/mobile-phones/brands/Nokia.webp",
    alt_text: "Nokia",
    categoryName: "Nokia",
    link: "/nokia-phones",
  },
];
