import { useGetLaptopsQuery } from "../../../../features/apiSlice";
import ListProducts from "../../ListProducts";

export default function PcLaptops() {
  return (
    <ListProducts
      apiData={useGetLaptopsQuery}
      breadcrumbsitems={["Home", "Electronics", "Laptops"]}
    />
  );
}

// <ProductCategoryPages
//   breadcrumbsitems={[
//     "Home",
//     "Electronics",
//     "Laptops & Accessories",
//     "PC Laptops",
//   ]}
//   category={
//     <SubCategoryPages
//       data={laptopBrands}
//       title={"Shop by Category"}
//       docTitle={"Laptops and Many More"}
//       categoryItems={laptopBrands}
//     />
//   }
// />
