import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { ProductCategoryPages } from "../../../../../CategoryPage";
import { mobilePhoneCategories } from "../../../../../data/Electronics/mobile-phones/mobilePhones";
import CategoryPages from "../../../CategoryPage";
import MobilePhoneCategoriesDesktop from "../MobilePhoneCategoryPageDesktop";
import MobilePhonesCategoryMobile from "./MobilePhoneCategoryPageMobile";

export default function MobilePhoneCategoryPage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    document.title = "Cell Phones & Accessories";
  }, []);
  return (
    <>
      {matches ? (
        <MobilePhonesCategoryMobile matches={matches} />
      ) : (
        <MobilePhoneCategoriesDesktop matches={matches} />
      )}
    </>
  );
}

export function MobilePhoneAndAccessories() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={["Home", "Electronics", "Cell Phones & Accessories"]}
      category={
        <CategoryPages
          bannerImage={"images/electronics/banners/mobilephonecategory.webp"}
          data={mobilePhoneCategories}
          title={"Shop by Brand"}
          docTitle={"Cell Phones & Accessories"}
          categoryItems={[
            { name: "Cell Phone Parts", link: "#" },
            { name: "Cell Phones Accessories ", link: "#" },
            { name: "Smart Watches ", link: "#" },
            { name: "Smart Watch Accessories ", link: "#" },
            { name: "Portable Audio & Headphones ", link: "#" },
            { name: "Phone & Sim Cards ", link: "#" },
            { name: "Vintage Cell Phones ", link: "#" },
          ]}
        />
      }
    />
  );
}
