import React from "react";
import { Container } from "@mui/system";
import NavBarMobile from "../../components/NavBar/navbarMobile";
import Footer from "../../components/Footer/Footer";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

// Images
import sony from "./img/sony.webp";
import fashion from "./img/fashion.webp";
import laptop from "./img/laptop.webp";
import others from "./img/others.webp";
import apple from "./img/apple.webp";
import accessories from "./img/accessories.webp";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import MultiCarouselMobile from "../../components/Carousel/MultiCarousalMobile";

const CategoryDiv = styled("div")({
  textTransform: "none",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
});

const CategoryTitle = styled(Button)({
  textTransform: "none",
});

const SectionHeader = styled("h3")({
  fontSize: "1rem",
  lineHeight: "28px",
  fontWeight: "500",
  textTransform: "none",
});

const useStyles = makeStyles(() => ({
  image: {
    height: "100px",
  },

  grid: {
    display: "flex",
    flexDirection: "column",
  },

  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
  },

  button: {
    textTransform: "none",
  },
}));

export default function HomeMobile() {
  const classes = useStyles();
  return (
    <React.Fragment>
      {
        <Container>
          <NavBarMobile />
        </Container>
      }

      <main>
        <Container style={{ paddingTop: "10px" }}>
          <section>
            <Container>
              <SectionHeader>Explore popular Categories</SectionHeader>
            </Container>

            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                justifyContent: "center",
                paddingLeft: "5%",
              }}
            >
              <Grid item>
                <CategoryDiv>
                  <Link to="apple-iphones" style={{ textDecoration: "none" }}>
                    <img
                      style={{
                        height: "90px",
                      }}
                      src={apple}
                      alt="apple"
                    ></img>
                  </Link>
                  <Link to="cell-phones-smart-watches-accessories" style={{ textDecoration: "none" }}>
                    <CategoryTitle>Phones</CategoryTitle>
                  </Link>
                </CategoryDiv>
              </Grid>

              <Grid item>
                <CategoryDiv>
                  <Link to="laptops" style={{ textDecoration: "none" }}>
                    <img
                      style={{
                        height: "90px",
                      }}
                      src={laptop}
                      alt=""
                    ></img>
                  </Link>
                  <Link to="laptops" style={{ textDecoration: "none" }}>
                    <CategoryTitle>Laptops</CategoryTitle>
                  </Link>
                </CategoryDiv>
              </Grid>

              <Grid item>
                <CategoryDiv>
                  <Link to="video-games" style={{ textDecoration: "none" }}>
                    <img
                      style={{
                        height: "90px",
                      }}
                      src={sony}
                      alt=""
                    ></img>
                  </Link>
                  <Link to="video-games" style={{ textDecoration: "none" }}>
                    <CategoryTitle>Games</CategoryTitle>
                  </Link>
                </CategoryDiv>
              </Grid>

              <Grid item>
                <CategoryDiv>
                  <Link to="fashion" style={{ textDecoration: "none" }}>
                    <img
                      style={{
                        height: "90px",
                      }}
                      src={fashion}
                      alt=""
                    ></img>
                  </Link>
                  <Link to="fashion" style={{ textDecoration: "none" }}>
                    <CategoryTitle>Fashion</CategoryTitle>
                  </Link>
                </CategoryDiv>
              </Grid>
              <Grid item>
                <CategoryDiv>
                  <Link to="accessories" style={{ textDecoration: "none" }}>
                    <img
                      style={{
                        height: "90px",
                      }}
                      src={accessories}
                      alt=""
                    ></img>
                  </Link>
                  <Link to="accessories" style={{ textDecoration: "none" }}>
                    <CategoryTitle>Accessories</CategoryTitle>
                  </Link>
                </CategoryDiv>
              </Grid>
              <Grid item>
                <CategoryDiv>
                  <Link to="others" style={{ textDecoration: "none" }}>
                    <img
                      style={{
                        height: "90px",
                      }}
                      src={others}
                      alt=""
                    ></img>
                  </Link>

                  <Link to="others" style={{ textDecoration: "none" }}>
                    <CategoryTitle>Others</CategoryTitle>
                  </Link>
                </CategoryDiv>
              </Grid>
            </Grid>
          </section>

          <section>
            <Container className={classes.container}>
              <SectionHeader>Daily Deals</SectionHeader>
              <div>
                <MultiCarouselMobile style={{ hieght: "1200px" }} />
              </div>
            </Container>
          </section>
        </Container>
      </main>
    </React.Fragment>
  );
}
