//import { useGetAppleMobilePhonesQuery } from "../../../../features/apiSlice";
//import ListProducts from "../../ListProducts";
import NoItemInCategoryPage from "../../NoItemInCategory";

export default function Imacs() {
  return (
    <NoItemInCategoryPage />
    //<ListProducts
    //  apiData={useGetAppleMobilePhonesQuery}
    //  breadcrumbsitems={["Home", "Laptops", "iMacs"]}
    ///>
  );
}