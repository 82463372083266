import { ProductCategoryPages } from "../../../../CategoryPage";
import { LaptopAccessories } from "../../../../data/Electronics/laptops/LaptopAndDesktopAccesories";
import SubCategoryPages from "../../SubCategoryPages";

export default function LaptopAndDesktopAccessories() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={[
        "Home",
        "Electronics",
        "Laptops & Desktop Accessories",
      ]}
      category={
        <SubCategoryPages
          data={LaptopAccessories}
          title={"Shop by Category"}
          docTitle={"Laptops & Desktop Accessories for sale | qualityncheap.com"}
          categoryItems={LaptopAccessories}
        />
      }
    />
  );
}
