import { ProductCategoryPages } from "../../../../CategoryPage";
import { iPadModels } from "../../../../data/Electronics/laptops/Ipads";
import SubCategoryPages from "../../SubCategoryPages";

export default function Ipads() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={[
        "Home",
        "Electronics",
        "Laptops & Accessories",
        "iPads",
      ]}
      category={
        <SubCategoryPages
          data={iPadModels}
          title={"Shop by Model"}
          docTitle={"Apple Tablets & Readers for sale | qualityncheap.com"}
          categoryItems={iPadModels}
        />
      }
    />
  );
}
