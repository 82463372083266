import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const commonStyles = {
  borderColor: "text.primary",
  m: 1,
  border: 0.5,
  width: "15rem",
  height: "2rem",
};

export function InputButton({ button: buttonText }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ ...commonStyles, borderRadius: "5px" }}>{buttonText}</Box>
    </Box>
  );
}

export function SubmitButton(props) {
  const { button, onclick, variant } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        variant={variant}
        sx={{
          ...commonStyles,
          borderRadius: "15px",
          border: "0",
          color: "white",
          justifyContent: "center",
        }}
        onClick={onclick}
      >
        <div style={{ textAlign: "center", alignContent: "center" }}>
          {button}
        </div>
      </Button>
    </Box>
  );
}

export function EmptyButton({ button: buttonText }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          ...commonStyles,
          borderRadius: "15px",
          border: "0.5px solid blue",
        }}
      >
        <div style={{ textAlign: "center", alignContent: "center" }}>
          {buttonText}
        </div>
      </Box>
    </Box>
  );
}
