import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Typography style={{ fontSize: "25px" }}>
        <span style={{ color: "blue" }}>Q</span>
        <span style={{ color: "red" }}>&</span>
        <span style={{ color: "green" }}>C</span>
      </Typography>
    </Link>
  );
}
