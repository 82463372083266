import { useGetAppleMobilePhonesQuery } from "../../../../features/apiSlice";
import ListProducts from "../../ListProducts";
import NoItemInCategoryPage from "../../NoItemInCategory";

export default function Monitors() {
  return (
   // <ListProducts
   //   apiData={useGetAppleMobilePhonesQuery}
   //   breadcrumbsitems={["Home", "Electronics", "Monitors"]}
   // />
   <NoItemInCategoryPage />
  );
}