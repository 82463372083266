import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useRouteError } from "react-router-dom";

export default function ErrorPageMobile() {
  const error = useRouteError();

  console.error(error);
  return (
    <>
      <Container>
        <Box
          sx={
            {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }
          }
        >
          <Container maxWidth="xs">
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                //border: "2px solid red",
              }}
            >
              <Grid xs={12}>
                <img
                  src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                  alt=""
                  height={150}
                  style={{ objectFit: "cover" }}
                />
              </Grid>
              <Grid
                xs={12}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  //border: "2px solid red",
                }}
              >
                <div
                  style={{
                    //justifyContent: "center",
                    //alignItems: "center",
                    textAlign: "center",
                    //border: "2px solid red",
                  }}
                >
                  <Typography variant="h6">
                    {/* The page you’re looking for doesn’t exist. */}
                    Oops! Page Not Found.
                  </Typography>
                  <Button href="/" variant="contained">
                    Back Home
                  </Button>
                </div>
                {/* <Typography variant="h1">404</Typography> */}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </>
  );
}
