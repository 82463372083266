import React from "react";
import { Container } from "@mui/system";
import NavBarDesktop, { TopBar } from "../../components/NavBar/navbarDestop";
import MenuBar from "../../components/MenuBar/MenuBar";
import Slider from "../../components/Carousel/Carousel";
import Footer from "../../components/Footer/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid from "@mui/material/Grid";
import { Link, NavLink } from "react-router-dom";

import { Typography } from "@mui/material";
import MultiColumCarousel from "../../components/Carousel/MultiColumCarousel";

//Images
import sony from "./img/sony.webp";
import fashion from "./img/fashion.webp";
import laptop from "./img/laptop.webp";
import others from "./img/others.webp";
import apple from "./img/apple.webp";
import accessories from "./img/accessories.webp";
import sell from "./img/sell.webp";

export default function HomeDesktop() {
  return (
    <React.Fragment>
      <header>
        <TopBar />
        <Container>
          <NavBarDesktop />
          <MenuBar />
        </Container>
      </header>
      <main>
        <section>
          <Container>
            <Slider />
          </Container>
        </section>
        <section>
          <Container
            style={{
              paddingTop: "30px",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <h2 style={{ fontSize: "20px" }}>
              Explore popular Categories <ArrowForwardIcon />
            </h2>
            <Grid container spacing={2.5} style={{ paddingTop: "20px" }}>
              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <NavLink
                    to="cell-phones-smart-watches-accessories"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img
                      src={apple}
                      alt="phones"
                      style={{ height: "130px" }}
                    ></img>
                    <Typography
                      style={{ fontFamily: "Market Sans, Arial, sans-serif" }}
                    >
                      Phones
                    </Typography>
                  </NavLink>
                </div>
              </Grid>

              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <Link
                    to="laptops"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img
                      src={laptop}
                      alt="laptops"
                      style={{ height: "130px" }}
                    ></img>
                    <Typography>Laptops</Typography>
                  </Link>
                </div>
              </Grid>

              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <Link
                    to="video-games"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img
                      src={sony}
                      alt="games"
                      style={{ height: "130px" }}
                    ></img>
                    <Typography>Games</Typography>
                  </Link>
                </div>
              </Grid>

              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <Link
                    to="fashion"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img
                      src={fashion}
                      alt="fashion"
                      style={{ height: "130px" }}
                    ></img>
                    <Typography>Fashion</Typography>
                  </Link>
                </div>
              </Grid>

              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <Link
                    to="electronics"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img
                      src={accessories}
                      alt="accessories"
                      style={{ height: "130px" }}
                    ></img>
                    <Typography>Accessories</Typography>
                  </Link>
                </div>
              </Grid>

              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <Link to="others" style={{ textDecoration: "none", color: "black" }}>
                    <img
                      src={others}
                      alt="others"
                      style={{ height: "130px" }}
                    ></img>
                    <Typography>Others</Typography>
                  </Link>
                </div>
              </Grid>

              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <img src={sell} alt="sell" style={{ height: "130px" }}></img>
                  <Typography>Sell</Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section>
          <Container
            style={{
              paddingTop: "50px",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <h2 style={{ fontSize: "20px" }}>
              Daily Deals | See All <ArrowForwardIcon />
            </h2>
            <Container>
              <MultiColumCarousel
                style={{ height: "50px" }}
              ></MultiColumCarousel>
            </Container>
          </Container>
        </section>
        <section></section>
      </main>
    </React.Fragment>
  );
}
