// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import ActionAreaCard from "../ImageCard/ImageCard";

import pro from "./11 pro-max.jpeg";
import iphonese from "./iphone-se-1.jpeg";
import iphone13 from "./iphone-13-1.jpeg";
import ps4 from "./ps5-1.jpeg";
import nokia from "./Nokia-105.jpeg";
import macbookPro from "./macbook-pro.jpeg";
import macbookAir from "./macbook-air.jpeg";
import { CarouselImage } from "./MultiColumCarousel";

export default function MultiCarouselMobile() {
  return (
    <>
      <Swiper
        slidesPerView={1.5}
        centeredSlides={true}
        spaceBetween={100}
        grabCursor={true}
        loop="true"
        pagination={{
        clickable: true,
        }}
        modules={[Pagination]}

        //className="mySwiper"
      >
        <SwiperSlide>
          <CarouselImage
            product_image={ps4}
            product_title="Sony Playstation 5"
            product_condition="Brand New"
            product_price="GHS 8000"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={iphone13}
            product_title="iPhone 13 (128GB) Unlocked"
            product_condition="Brand New"
            product_price="GHS 7300"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={pro}
            product_title="Apple iPhone XS Max (256GB)"
            product_condition="Used"
            product_price="GHS 3600"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={nokia}
            product_title="Nokia 105 (Dual Sim)"
            product_condition="Brand New"
            product_price="GHS 150"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={iphonese}
            product_title="Apple iPhone SE (128GB)"
            product_condition="Brand New"
            product_price="GHS 4500"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={macbookPro}
            product_title="Apple Macbook Pro M1 (8gb/256ssd)"
            product_condition="Brand New"
            product_price="GHS 12000"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={macbookAir}
            product_title="Apple Macbook Pro M1 (8gb/256ssd)"
            product_condition="Brand New"
            product_price="GHS 11500"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
