export const desktopBrands = [
  {
    id: 1,
    image: "images/electronics/laptops/brands/acer.webp",
    alt_text: "Acer Laptops",
    categoryName: "Acer",
    link: "/acer-computers",
  },
  {
    id: 2,
    image: "images/electronics/laptops/brands/asus.webp",
    alt_text: "Asus Laptops",
    categoryName: "Asus",
    link: "/asus-computers",
  },
  {
    id: 3,
    image: "images/electronics/laptops/brands/dell.svg",
    alt_text: "Dell Laptops",
    categoryName: "Dell",
    link: "/dell-computers",
  },
  {
    id: 4,
    image: "images/electronics/laptops/brands/hp.svg.png",
    alt_text: "Google laptops",
    categoryName: "Google",
    link: "/google-computers",
  },
  {
    id: 5,
    image: "images/electronics/laptops/brands/lenovo.png",
    alt_text: "Lenovo laptops",
    categoryName: "Lenovo",
    link: "/lenovo-computers",
  },
  {
    id: 6,
    image: "images/electronics/laptops/brands/msi.webp",
    alt_text: "Msi laptops",
    categoryName: "Msi",
    link: "/msi-computers",
  },
  {
    id: 7,
    image: "images/electronics/laptops/brands/sony.jpeg",
    alt_text: "Sony laptops",
    categoryName: "Sony",
    link: "/sony-computers",
  },
  {
    id: 8,
    image: "images/electronics/laptops/brands/toshiba.png",
    alt_text: "Toshiba laptops",
    categoryName: "Toshiba",
    link: "/toshiba-computers",
  },
];
