export const LaptopAccessories = [
  {
    id: 1,
    image: "images/electronics/laptops-and-desktop-accessories/laptop-batteries.webp",
    alt_text: "Laptop Batteries",
    categoryName: "Laptop Batteries",
    link: "/laptop-batteries",
  },
  {
    id: 2,
    image: "images/electronics/laptops-and-desktop-accessories/laptop-power-adapters.webp",
    alt_text: "Laptop Power Adapters/Chargers",
    categoryName: "Laptop Power Adapters/Chargers",
    link: "/laptop-power-adapters-and-chargers",
  },
  {
    id: 3,
    image: "images/electronics/laptops-and-desktop-accessories/laptop-docking-stations.webp",
    alt_text: "Laptop Docking Stations",
    categoryName: "Laptop Docking Station",
    link: "/laptop-docking-station",
  },
  {
    id: 4,
    image: "images/electronics/laptops-and-desktop-accessories/laptop-cases-bags.webp",
    alt_text: "Laptop Cases and Bags",
    categoryName: "Laptop Cases & Bags",
    link: "/laptop-cases-and-bags",
  },
  {
    id: 5,
    image: "images/electronics/laptops-and-desktop-accessories/computer-speakers.webp",
    alt_text: "Computer Speakers",
    categoryName: "Computer Speakers",
    link: "/computer-speakers",
  },
  {
    id: 6,
    image: "images/electronics/laptops-and-desktop-accessories/mouse-pads.webp",
    alt_text: "Mouse Pads & Wrist Rests",
    categoryName: "Mouse Pads & Wrist Rest",
    link: "/mouse-pads-and-wrist-rests",
  },
  {
    id: 7,
    image: "images/electronics/laptops-and-desktop-accessories/webcams.webp",
    alt_text: "Webcams",
    categoryName: "Webcams",
    link: "/webcams",
  },
  {
    id: 8,
    image: "images/electronics/laptops-and-desktop-accessories/headsets.webp",
    alt_text: "Headsets",
    categoryName: "Headsets",
    link: "/headsets",
  },
  {
    id: 9,
    image: "images/electronics/laptops-and-desktop-accessories/memory-cards.webp",
    alt_text: "Memory Cards and Readers",
    categoryName: "Memory Cards & Readers",
    link: "/memory-cards-and-readers",
  },
  {
    id: 10,
    image: "images/electronics/laptops-and-desktop-accessories/stands-and-holders.webp",
    alt_text: "Stands and Holders",
    categoryName: "Stands, Holders & Car Mounts",
    link: "/stands-holders-and-car-mounts",
  },
  {
    id: 11,
    image: "images/electronics/laptops-and-desktop-accessories/case-mods.webp",
    alt_text: "Case Mods, Stickers and Decals",
    categoryName: "Case Mods, Stickers and Decals",
    link: "/case-mods-stickers-and-decals",
  },
  {
    id: 12,
    image: "images/electronics/laptops-and-desktop-accessories/other-accessories.webp",
    alt_text: "All Other Accessories",
    categoryName: "All Other Accessories",
    link: "/all-other-laptop-and-desktop-accessories",
  },

];
