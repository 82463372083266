import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import HomeDesktop from "./HomeDesktop";
import HomeMobile from "./HomeMobile"
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    document.title = "Electronic, Clothings, and Many More";
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {matches ? (
        <HomeMobile matches={matches} />
      ) : (
        <HomeDesktop matches={matches} />
      )}
    </>
  );
}
