import { Box, Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import Checkboxes from "../../components/CheckBox/CheckBox";
import BigLogo from "../../components/Logo/BigLogo";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function SignIn() {
  useEffect(() => {
    document.title = "Sign in or Register | QnC";
  }, []);
  return (
    <>
      <Grid container md={12} style={{ paddingTop: "50px", margin: "20px" }}>
        <Grid item md={2} style={{ marginLeft: "12px" }}>
          <BigLogo />
        </Grid>
      </Grid>
      <Container style={{ textAlign: "center" }}>
        <h2>Hello</h2>
        <h2 style={{ fontSize: "15px" }}>
          Sign in to QnC or{" "}
          <a href="/" style={{ textTransform: "none" }}>
            create an account
          </a>
        </h2>

        <div>
          <Stack spacing={10} direction="column">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
              }}
              noValidate
              autoComplete="off"
              style={{ textAlign: "center" }}
            >
              <div style={{ textAlign: "center" }}>
                <TextField
                  id="outlined-basic"
                  label="Email or Username"
                  variant="outlined"
                  size="small"
                  style={{ width: "20rem" }}
                />
              </div>
              <div>
                <Button
                  style={{ width: "20rem", marginTop: ".5rem" }}
                  variant="contained"
                >
                  Continue
                </Button>
              </div>
              <div style={{ marginTop: "1rem" }}>or</div>

              <div>
                <Button
                  style={{ width: "20rem", marginTop: "1rem" }}
                  variant="contained"
                >
                  Continue with Facebook{" "}
                </Button>
              </div>
              <div>
                <Button
                  style={{ width: "20rem", marginTop: "1rem" }}
                  variant="outlined"
                >
                  Continue with Google{" "}
                </Button>
              </div>
              <div>
                <Button
                  style={{ width: "20rem", marginTop: "1rem" }}
                  variant="outlined"
                >
                  Continue with Apple{" "}
                </Button>
              </div>
            </Box>
          </Stack>
        </div>

        <Container>
          <Grid container style={{ textAlign: "center" }}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <div>
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Checkboxes />
                    <div style={{ fontSize: "13px", fontWeight: "bold" }}>
                      Stay signed in
                    </div>
                  </Container>
                </div>
              </div>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Container>

        <div>
          <div style={{ fontSize: "13px", fontWeight: "bold" }}>
            Using a public or shared device? <br />
            Uncheck to protect your account.
            <br />
            Learn more
          </div>
        </div>
      </Container>
    </>
  );
}
