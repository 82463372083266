import {
  ProductCategoryPageDesktop,
  ProductCategoryPageMobile,
} from "./CategoryPages";
import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";

export default function CategoryPages(props) {
  const { docTitle, categoryItems, bannerImage, data, title } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    document.title = docTitle;
  }, []);
  return (
    <>
      {matches ? (
        <ProductCategoryPageMobile
          data={data}
          title={title}
          matches={matches}
        />
      ) : (
        <ProductCategoryPageDesktop
          data={data}
          title={title}
          bannerImage={bannerImage}
          categoryItems={categoryItems}
          matches={matches}
        />
      )}
    </>
  );
}
