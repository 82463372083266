import { useGetMacbooksQuery } from "../../../../features/apiSlice";
import ListProducts from "../../ListProducts";

export default function Macbooks() {
  return (
    <ListProducts
     apiData={useGetMacbooksQuery}
     breadcrumbsitems={["Home", "Laptops", "Macbooks"]}
    />
  );
}
