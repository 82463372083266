import { ProductCategoryPages } from "../../../../CategoryPage";
import { desktopBrands } from "../../../../data/Electronics/laptops/Desktop-and-all-in-one-PCs";
import SubCategoryPages from "../../SubCategoryPages";

export default function DesktopsAndOthers() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={[
        "Home",
        "Electronics",
        "Laptops & Accessories",
        "Desktops and All-in-one-PCs",
      ]}
      category={
        <SubCategoryPages
          data={desktopBrands}
          title={"Shop by Brand"}
          docTitle={"PC Desktops & All-in-One Computer for sale | QnC"}
          categoryItems={desktopBrands}
        />
      }
    />
  );
}
