import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import PageBreadcrumbs from "../../../components/Breadcrumb/Breadcrumb";
import { SubmitButton } from "../../../components/Buttons/Buttons";
import ImageCarouselMobile from "../../../components/Carousel/ImageCarouselMobile";
import NavBar from "../../../components/NavBar";
import { useGetSingleProductQuery } from "../../../features/apiSlice";
import WhatsappButton from "../../../components/ReactWhatsapp/ReactWhatsapp";
import CircularIndeterminate from "../../../components/Progress/Progress";

export default function SingleProductPageMobile() {
  const params = useParams();

  const { data, isLoading, isSuccess, isError, error } =
    useGetSingleProductQuery(params.product_id);

  if (isLoading) {
    return (
      <>
        <Container>
          <div>
            <NavBar />
          </div>
          <div>
            <PageBreadcrumbs
              breadcrumbsitems={["Home", "Electronics", "Mobile Phones"]}
            />
          </div>
          <Container>
            <div>
              <CircularIndeterminate />
            </div>
          </Container>
        </Container>
      </>
    );
  } else if (isSuccess) {
    return (
      <>
        <Container>
          <div>
            <NavBar />
          </div>
          <div>
            <PageBreadcrumbs
              breadcrumbsitems={["Home", "Electronics", "Mobile Phones"]}
            />
          </div>
          <Grid container spacing={3} style={{ paddingTop: "20px" }}>
            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <ImageCarouselMobile data={data} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ paddingTop: "20px" }}>
                  <h5>{data.title}</h5>
                </div>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <h6>GHS {data.cash_price}</h6>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: " #7CB9E8",
                    }}
                  >
                    <p style={{ color: " #7CB9E8" }}>
                      Pay Later: GHS {data.loan_price}
                    </p>
                  </div>
                </Grid>
              </Grid>

              <Grid container style={{ paddingTop: "20px" }}>
                <Grid item xs={6}>
                  <h6>Brand</h6>
                  <p>{data.product_brand}</p>
                </Grid>
                <Grid item xs={6}>
                  <h6>Condition</h6>
                  <p>{data.condition}</p>
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <SubmitButton
                    button="CALL: 0207597903"
                    variant="contained"
                  ></SubmitButton>
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton
                    variant="contained"
                    button={<WhatsappButton />}
                  ></SubmitButton>
                </Grid>
              </Grid>

              <Grid container style={{ paddingTop: "20px" }}>
                <Grid item xs={12}>
                  <h5>Description</h5>
                </Grid>
                <Grid container>
                  {data.product_specification.map((specification) => (
                    <Grid item xs={12}>
                      <p>
                        <em style={{ color: "#707070", fontWeight: "bold" }}>
                          {specification.specification}{" "}
                        </em>
                        : {specification.value}{" "}
                      </p>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
