// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import pro from "./11 pro-max.jpeg";
import iphonese from "./iphone-se-1.jpeg";
import iphone13 from "./iphone-13-1.jpeg";
import ps4 from "./ps5-1.jpeg";
import nokia from "./Nokia-105.jpeg";
import macbookPro from "./macbook-pro.jpeg";
import macbookAir from "./macbook-air.jpeg";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";

export function CarouselImage({
  product_image,
  product_title,
  product_condition,
  product_price,
}) {
  return (
    <div>
      <img
        src={product_image}
        alt="pro"
        style={{
          height: "225px",
          objectFit: "contain",
        }}
      ></img>
      <div style={{ paddingTop: "5px" }}>
        <Typography>{product_title}</Typography>
        <Typography
          style={{
            color: "#708090",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {product_condition}
        </Typography>
        <Typography
          style={{
            paddingTop: "5px",
            fontWeight: "600",
            fontSize: "15px",
          }}
        >
          {product_price}
        </Typography>
      </div>
    </div>
  );
}

export default function MultiColumCarousel() {
  return (
    <>
      <Swiper
        slidesPerView={4}
        centeredSlides={true}
        spaceBetween={100}
        grabCursor={true}
        //pagination={{
        // clickable: true,
        // }}
        //modules={[Pagination]}
        //className="mySwiper"
        loop={true}
      >
        <SwiperSlide>
          <CarouselImage
            product_image={ps4}
            product_title="Sony Playstation 5"
            product_condition="Brand New"
            product_price="GHS 8000"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={iphone13}
            product_title="iPhone 13 (128GB) Unlocked"
            product_condition="Brand New"
            product_price="GHS 7300"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={pro}
            product_title="Apple iPhone XS Max (256GB)"
            product_condition="Used"
            product_price="GHS 3600"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={nokia}
            product_title="Nokia 105 (Dual Sim)"
            product_condition="Brand New"
            product_price="GHS 150"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={iphonese}
            product_title="Apple iPhone SE (128GB)"
            product_condition="Brand New"
            product_price="GHS 4500"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={macbookPro}
            product_title="Apple Macbook Pro M1 (8gb/256ssd)"
            product_condition="Brand New"
            product_price="GHS 12000"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            product_image={macbookAir}
            product_title="Apple Macbook Pro M1 (8gb/256ssd)"
            product_condition="Brand New"
            product_price="GHS 11500"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
