import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Footer from "../components/Footer/Footer";
import TestFooter from "../components/Footer/Footer copy";
import TestLazyLoadedImageCard from "../components/ImageCard/LazyLoadedImageCard copy";
import TestCircularIndeterminate from "../components/Progress/Progress copy";
import ErrorPageMobile from "../pages/Error/404PageMobile";
import { Electronics } from "../pages/ProductCategoryPages/Electronics";
import Imacs from "../pages/ProductCategoryPages/Electronics/laptops/iMacs";
import Ipads from "../pages/ProductCategoryPages/Electronics/laptops/Ipads";
import Laptops from "../pages/ProductCategoryPages/Electronics/laptops/Laptops";
import Macbooks from "../pages/ProductCategoryPages/Electronics/laptops/Macbooks";
import PcLaptops from "../pages/ProductCategoryPages/Electronics/laptops/PcLaptops";
import {
  AlcatelPhones,
  AppleiPhones,
  BlackBerryPhones,
  GooglePhones,
  HtcPhones,
  HuaweiPhones,
  InfinixPhones,
  ItelPhones,
  LgPhones,
  MotorollaPhones,
  NokiaPhones,
  OnePlusPhones,
  SamsungPhones,
  TechnoPhones,
} from "../pages/ProductCategoryPages/Electronics/mobilePhones/Brands/MobilePhones";
import { MobilePhoneAndAccessories } from "../pages/ProductCategoryPages/Electronics/mobilePhones/MobilePhonesCategoryPage";
import NoItemInCategoryPage from "../pages/ProductCategoryPages/NoItemInCategory";
import SingleProductPage from "../pages/ProductCategoryPages/SingleProductPage/SingleProductPage";

export const router = createBrowserRouter([
  // Base Route
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPageMobile />,
  },

  // General routes
  {
    path: "singleproduct/:product_id",
    element: <SingleProductPage />,
  },
  {
    path: "test",
    element: <TestLazyLoadedImageCard />,
  },

  // Electronics Section
  {
    path: "electronics",
    element: <Electronics />,
  },
  {
    path: "car-electronics",
    element: <NoItemInCategoryPage />,
  },

  // Mobile Phone and Accessories Section
  {
    path: "cell-phones-smart-watches-accessories",
    element: <MobilePhoneAndAccessories />,
  },
  {
    path: "apple-iphones",
    element: <AppleiPhones />,
  },
  {
    path: "samsung-phones",
    element: <SamsungPhones />,
  },
  {
    path: "techno-phones",
    element: <TechnoPhones />,
  },
  {
    path: "itel-phones",
    element: <ItelPhones />,
  },
  {
    path: "infinix-phones",
    element: <InfinixPhones />,
  },
  {
    path: "alcatel-phones",
    element: <AlcatelPhones />,
  },
  {
    path: "google-phones",
    element: <GooglePhones />,
  },
  {
    path: "huawei-phones",
    element: <HuaweiPhones />,
  },
  {
    path: "lg-phones",
    element: <LgPhones />,
  },
  {
    path: "htc-phones",
    element: <HtcPhones />,
  },
  {
    path: "one-plus-phones",
    element: <OnePlusPhones />,
  },
  {
    path: "blackberry-phones",
    element: <BlackBerryPhones />,
  },
  {
    path: "motorolla-phones",
    element: <MotorollaPhones />,
  },
  {
    path: "nokia-phones",
    element: <NokiaPhones />,
  },

  // Computers & Tablets Section
  {
    path: "laptops",
    element: <Laptops />,
  },
  {
    path: "pc-laptops",
    element: <PcLaptops />,
  },
  {
    path: "macbooks",
    element: <Macbooks />,
  },
  {
    path: "imacs",
    element: <Imacs />,
  },
  {
    path: "ipads",
    element: <Ipads />,
  },

  // iPads Section
  {
    path: "Apple-iPad-Pro-1st-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Pro-5th-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Pro-4th-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Pro-3rd-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Air-2",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-9th-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Air-5th-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Pro-4th-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-mini-4",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-mini-5th-Generation",
    element: <NoItemInCategoryPage />,
  },
  {
    path: "Apple-iPad-Pro-2nd-Generation",
    element: <NoItemInCategoryPage />,
  },

  // Printers Section
  {
    path: "printers",
    element: <NoItemInCategoryPage />,
    //element: <Printers />,
  },

  // TVs & Home Electronics Section
  {
    path: "televisions-and-home-electronics",
    element: <NoItemInCategoryPage />,
  },

  // Camera & Photos Section
  {
    path: "cameras-and-photos",
    element: <NoItemInCategoryPage />,
  },

  // Health & Beauty Section
  {
    path: "health_&_beauty",
    element: <NoItemInCategoryPage />,
  },

  // Home & Garden Section
  {
    path: "home_&_garden",
    element: <NoItemInCategoryPage />,
  },

  // Sports Section
  {
    path: "sports",
    element: <NoItemInCategoryPage />,
  },

  // Fashion Section
  {
    path: "fashion",
    element: <NoItemInCategoryPage />,
  },

  // Video Games Section
  {
    path: "video-games",
    element: <NoItemInCategoryPage />,
  },

  // Home Devices Section
  {
    path: "home-devices",
    element: <NoItemInCategoryPage />,
  },

  // Accessories Section
  {
    path: "accessories",
    element: <NoItemInCategoryPage />,
  },

  // Auto Section
  {
    path: "auto",
    element: <NoItemInCategoryPage />,
  },

  // Collectible Arts Section
  {
    path: "collectibles_&_arts",
    element: <NoItemInCategoryPage />,
  },

  // Collectible Arts Section
  {
    path: "industrial_equipment",
    element: <NoItemInCategoryPage />,
  },

  // Sell Section
  {
    path: "sell",
    element: <NoItemInCategoryPage />,
  },

  // Others
  {
    path: "others",
    element: <NoItemInCategoryPage />,
  },

  // Deals
  {
    path: "deals",
    element: <NoItemInCategoryPage />,
  },
]);
