import { ProductCategoryPages } from "../../../CategoryPage";
import CategoryPages from "../CategoryPage";
import { electronics } from "../../../data/ElectronicsCategories";

export function Electronics() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={["Home", "Electronics", "Laptops & Accessories"]}
      category={
        <CategoryPages
          bannerImage={"images/electronics/banners/electro_1.png"}
          data={electronics}
          title={"Shop by Category"}
          docTitle={"Electronics products for sale | QnC"}
          categoryItems={[
            {
              name: "Cell Phones",
              link: "/cell-phones-smart-watches-accessories",
            },
            {
              name: "Phone Accessories",
              link: "/cell-phones-smart-watches-accessories",
            },
            { name: "Computers & Tablets", link: "/laptops" },
            { name: "Computer Accessories ", link: "#" },
            {
              name: "Drives,  Storage & Blank Media ",
              link: "#",
            },
          ]}
        />
      }
    />
  );
}
