export const TVandHomeAudioCategory = [
  {
    id: "1",
    image: "images/electronics/TvHomeAudioCategory/tvs.webp",
    alt_text: "TVs",
    categoryName: "TVs",
    link: "#",
  },
  {
    id: "2",
    image: "images/electronics/TvHomeAudioCategory/speakers.webp",
    alt_text: "Speakers & Subwoofers",
    categoryName: "Speakers & Subwoofers",
    link: "#",
  },
  {
    id: "3",
    image: "images/electronics/TvHomeAudioCategory/recievers.webp",
    alt_text: "Recievers",
    categoryName: "Recievers",
    link: "#",
  },
  {
    id: "4",
    image: "images/electronics/TvHomeAudioCategory/amplifiers.webp",
    alt_text: "Amplifiers and Preamps",
    categoryName: "Amplifiers and Preamps",
    link: "#",
  },
  {
    id: "5",
    image: "images/electronics/TvHomeAudioCategory/cd-player.webp",
    alt_text: "CD Players & Recorders",
    categoryName: "CD Players & Recorders",
    link: "#",
  },
  {
    id: "6",
    image: "images/electronics/TvHomeAudioCategory/record-player.webp",
    alt_text: "Record Players & Turntables",
    categoryName: "Record Players & Turntables",
    link: "#",
  },
  {
    id: "7",
    image: "images/electronics/TvHomeAudioCategory/dvd-players.webp",
    alt_text: "DVD & Blu-ray Players",
    categoryName: "DVD & Blu-ray Players",
    link: "#",
  },
  {
    id: "8",
    image: "images/electronics/TvHomeAudioCategory/media-streamers.webp",
    alt_text: "Media Streamers",
    categoryName: "Media Streamers",
    link: "#",
  },
  {
    id: "9",
    image: "images/electronics/TvHomeAudioCategory/equalizers.webp",
    alt_text: "Equalizers",
    categoryName: "Equalizers",
    link: "#",
  },
  {
    id: "10",
    image: "images/electronics/TvHomeAudioCategory/projectors.webp",
    alt_text: "Projectors",
    categoryName: "Projectors",
    link: "#",
  },
];
