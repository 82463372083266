import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://store-api.qualityncheap.com/",
  }),
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => "all-products/",
    }),

    // Mobile Phone End Points
    getAllMobile_phones: builder.query({
      query: () => "mobile-phones/",
    }),

    getMobilePhones: builder.query({
      query: (id) => `/mobile-phones/${id}`,
    }),

    getFilterMobilePhonesBrands: builder.query({
      query: (id) => ({ url: `filter-mobile-phones/${id}/` }),
      transformResponse: (response, meta, arg) => response.data,
    }),

    getAppleMobilePhones: builder.query({
      query: () => `apple-iphones/`,
    }),

    getSamsungMobilePhones: builder.query({
      query: () => `samsung-mobile-phones/`,
    }),

    getTechnoMobilePhones: builder.query({
      query: () => `techno-mobile-phones/`,
    }),

    getItelMobilePhones: builder.query({
      query: () => `itel-mobile-phones/`,
    }),

    getInfinixMobilePhones: builder.query({
      query: () => `infinix-mobile-phones/`,
    }),

    getAlcatelMobilePhones: builder.query({
      query: () => `alcatel-mobile-phones/`,
    }),

    getGoogleMobilePhones: builder.query({
      query: () => `google-mobile-phones/`,
    }),

    getHuaweiMobilePhones: builder.query({
      query: () => `huawei-mobile-phones/`,
    }),

    getLgMobilePhones: builder.query({
      query: () => `lg-mobile-phones/`,
    }),

    getHtcMobilePhones: builder.query({
      query: () => `htc-mobile-phones/`,
    }),

    getOnePlusMobilePhones: builder.query({
      query: () => `one-plus-mobile-phones/`,
    }),

    getBlackberryMobilePhones: builder.query({
      query: () => `blackberry-mobile-phones/`,
    }),

    getMotorollaMobilePhones: builder.query({
      query: () => `motorolla-mobile-phones/`,
    }),

    getNokiaMobilePhones: builder.query({
      query: () => `nokia-mobile-phones/`,
    }),

    // Laptop End Points
    getLaptops: builder.query({
      query: () => `laptops/`,
    }),

    getMacbooks: builder.query({
     query: (id) => `macbooks/`,
    }),

    // getDellLaptops: builder.query({
    //   query: (id) => `/filter-pc-laptops/15`,
    // }),

    //getHpLaptops: builder.query({
    //  query: (id) => `/filter-pc-laptops/15`,
    //}),

    //getAcerLaptops: builder.query({
    //  query: (id) => `/filter-pc-laptops/15`,
    //}),

    // Desktop End Points

    // Single Product Filter
    getSingleProduct: builder.query({
      query: (id) => `/single-product-filter/${id}`,
    }),
  }),
});

export const {
  useGetAllProductsQuery,

  //Mobile Phone Query Points
  useGetAllMobile_phonesQuery,
  useGetMobilePhonesQuery,
  useGetAppleMobilePhonesQuery,
  useGetSamsungMobilePhonesQuery,
  useGetTechnoMobilePhonesQuery,
  useGetItelMobilePhonesQuery,
  useGetInfinixMobilePhonesQuery,
  useGetAlcatelMobilePhonesQuery,
  useGetGoogleMobilePhonesQuery,
  useGetHuaweiMobilePhonesQuery,
  useGetLgMobilePhonesQuery,
  useGetHtcMobilePhonesQuery,
  useGetOnePlusMobilePhonesQuery,
  useGetBlackberryMobilePhonesQuery,
  useGetMotorollaMobilePhonesQuery,
  useGetNokiaMobilePhonesQuery,

  useGetFilterMobilePhonesBrandsQuery,

  // Laptop Query Points
  useGetLaptopsQuery,
  useGetMacbooksQuery,
  //useGetDellLaptopsQuery,

  // Single Produc Filter
  useGetSingleProductQuery,
} = productsApi;
