import Carousel from "react-bootstrap/Carousel";
import { Container } from "@mui/system";
import home from "../Carousel/new/HOME.png";
import banner from "../Carousel/new/qnc_banner_1.png";
import automobile from "../Carousel/new/automobile.png";
import fashion from "../Carousel/new/qnc_banner_fashion.png";
import phones from "../Carousel/new/qnc_phone_banner.png";
import "bootstrap/dist/css/bootstrap.min.css";

function Slider() {
  return (
    <Container>
      <Carousel>
        <Carousel.Item>
          <img
            style={{ height: "300px", objectFit: "cover" }}
            className="d-block w-100"
            src={banner}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: "300px" }}
            className="d-block w-100"
            src={phones}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: "300px" }}
            className="d-block w-100"
            src={automobile}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: "300px" }}
            className="d-block w-100"
            src={fashion}
            alt="Fourth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ height: "300px" }}
            className="d-block w-100"
            src={home}
            alt="Fifth slide"
          />
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default Slider;
