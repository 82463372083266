import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function ProductPrice(props) {
  const { cash_price, loan_price } = props;
  let productPrice;
  let starting_loan_price = 500;

  if (cash_price > starting_loan_price) {
    productPrice = (
      <>
        <Typography
          style={{
            paddingTop: "5px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          GHS {cash_price}
        </Typography>

        <Typography
          style={{
            paddingTop: "5px",
            fontSize: "12px",
            color: "#00008B",
            fontWeight: "bold",
          }}
        >
          Pay Later: GHS {loan_price}
        </Typography>
      </>
    );
  } else {
    productPrice = (
      <Typography
        style={{
          paddingTop: "5px",
          fontWeight: "bold",
          fontSize: "15px",
        }}
      >
        GHS {cash_price}
      </Typography>
    );
  }
  return productPrice;
}

export default function TestLazyLoadedImageCard({ data, imageid }) {
  return (
    <Grid item xs={12} md={3}>
      <LazyLoadComponent>
        <div>
          {data.product_image.map((data) => (
            <img
              key={data.id}
              src={data.image}
              alt={data.alt}
              style={{
                height: "225px",
                maxWidth: "100%",
              }}
            ></img>
          ))}
        </div>
      </LazyLoadComponent>
      <Link
        to={`/singleproduct/${data.id}`}
        style={{
          textTransform: "none",
          textDecoration: "none",
        }}
      >
        <Button
          style={{
            textTransform: "none",
            textAlign: "left",
            padding: "0",
          }}
          id={imageid}
        >
          <Typography
            style={{
              overflowWrap: "break-word",
              inlineSize: "250px",
            }}
          >
            {data.title}
          </Typography>
        </Button>
      </Link>
      <Typography
        style={{
          paddingTop: "5px",
          color: "#708090",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {data.condition}
      </Typography>
      <ProductPrice cash_price={data.cash_price} loan_price={data.loan_price} />
    </Grid>
  );
}
