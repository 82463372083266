export const laptopCategories = [
  {
    id: 1,
    image: "images/electronics/laptops/categories/pc_laptops.webp",
    alt_text: "PC laptops",
    categoryName: "PC Laptops",
    link: "/pc-laptops",
  },
  {
    id: 2,
    image: "images/electronics/laptops/categories/macbooks.webp",
    alt_text: "Macbooks",
    categoryName: "Macbooks",
    link: "/macbooks",
  },
  {
    id: 3,
    image: "images/electronics/laptops/categories/ipads.webp",
    alt_text: "iPads",
    categoryName: "iPads",
    link: "/ipads",
  },
  {
    id: 4,
    image: "images/electronics/laptops/categories/pc_desktops.webp",
    alt_text: "desktops",
    categoryName: "PC Desktops & All-in-One Computers",
    link: "/pc-desktops-all-in-one-computers",
  },
  {
    id: 5,
    image: "images/electronics/laptops/categories/imacs.webp",
    alt_text: "imacs",
    categoryName: "iMacs",
    link: "/imacs",
  },
  {
    id: 6,
    image: "images/electronics/laptops/categories/laptop_accessories.webp",
    alt_text: "Laptop Accessories",
    categoryName: "Laptop & Desktop Accessories",
    link: "/laptops-desktop-accessories",
  },
  {
    id: 7,
    image: "images/electronics/laptops/categories/tablet accessories.webp",
    alt_text: "Tablet Accessories",
    categoryName: "Tablet Accessories",
    link: "/tablet-accessories",
  },
  {
    id: 8,
    image: "images/electronics/laptops/categories/printers.webp",
    alt_text: "printer",
    categoryName: "Printers",
    link: "/printers",
  },
  {
    id: 9,
    image: "images/electronics/laptops/categories/components_and_parts.webp",
    alt_text: "components & parts",
    categoryName: "Components & Parts",
    link: "/computer-components-and-parts",
  },
  {
    id: 10,
    image: "images/electronics/laptops/categories/monitors.webp",
    alt_text: "monitors",
    categoryName: "Monitors",
    link: "/monitors",
  },
];



export const laptopBrands = [
  {
    id: 1,
    image: "images/electronics/laptops/brands/acer.webp",
    alt_text: "Acer Laptops",
    categoryName: "Acer",
    link: "/acer-laptops",
  },
  {
    id: 2,
    image: "images/electronics/laptops/brands/asus.webp",
    alt_text: "Asus Laptops",
    categoryName: "Asus",
    link: "/asus-laptops",
  },
  {
    id: 3,
    image: "images/electronics/laptops/brands/dell.svg",
    alt_text: "Dell Laptops",
    categoryName: "Dell",
    link: "/dell-laptops",
  },
  {
    id: 4,
    image: "images/electronics/laptops/brands/google.webp",
    alt_text: "Google laptops",
    categoryName: "Google",
    link: "/google-laptops",
  },
  {
    id: 5,
    image: "images/electronics/laptops/brands/hp.svg.png",
    alt_text: "HP laptops",
    categoryName: "HP",
    link: "/HP-laptops",
  },
  {
    id: 6,
    image: "images/electronics/laptops/brands/lenovo.png",
    alt_text: "Lenovo laptops",
    categoryName: "Lenovo",
    link: "/lenovo-laptops",
  },
  {
    id: 7,
    image: "images/electronics/laptops/brands/msi.webp",
    alt_text: "Msi laptops",
    categoryName: "Msi",
    link: "/msi-laptops",
  },
  {
    id: 8,
    image: "images/electronics/laptops/brands/sony.jpeg",
    alt_text: "Sony laptops",
    categoryName: "Sony",
    link: "/sony-laptops",
  },
  {
    id: 9,
    image: "images/electronics/laptops/brands/toshiba.png",
    alt_text: "Toshiba laptops",
    categoryName: "Toshiba",
    link: "/toshiba-laptops",
  },
];
