import {
  useGetAlcatelMobilePhonesQuery,
  useGetAppleMobilePhonesQuery,
  useGetBlackberryMobilePhonesQuery,
  useGetGoogleMobilePhonesQuery,
  useGetHtcMobilePhonesQuery,
  useGetHuaweiMobilePhonesQuery,
  useGetInfinixMobilePhonesQuery,
  useGetItelMobilePhonesQuery,
  useGetLgMobilePhonesQuery,
  useGetMotorollaMobilePhonesQuery,
  useGetNokiaMobilePhonesQuery,
  useGetOnePlusMobilePhonesQuery,
  useGetSamsungMobilePhonesQuery,
  useGetTechnoMobilePhonesQuery,
} from "../../../../../features/apiSlice";
import MobilePhonePage from "../index";

// Mobile Phone pages

export function AppleiPhones() {
  return <MobilePhonePage apiData={useGetAppleMobilePhonesQuery} />;
}

export function SamsungPhones() {
  return <MobilePhonePage apiData={useGetSamsungMobilePhonesQuery} />;
}

export function TechnoPhones() {
  return <MobilePhonePage apiData={useGetTechnoMobilePhonesQuery} />;
}

export function ItelPhones() {
  return <MobilePhonePage apiData={useGetItelMobilePhonesQuery} />;
}

export function InfinixPhones() {
  return <MobilePhonePage apiData={useGetInfinixMobilePhonesQuery} />;
}

export function AlcatelPhones() {
  return <MobilePhonePage apiData={useGetAlcatelMobilePhonesQuery} />;
}

export function GooglePhones() {
  return <MobilePhonePage apiData={useGetGoogleMobilePhonesQuery} />;
}

export function HuaweiPhones() {
  return <MobilePhonePage apiData={useGetHuaweiMobilePhonesQuery} />;
}

export function LgPhones() {
  return <MobilePhonePage apiData={useGetLgMobilePhonesQuery} />;
}

export function HtcPhones() {
  return <MobilePhonePage apiData={useGetHtcMobilePhonesQuery} />;
}

export function OnePlusPhones() {
  return <MobilePhonePage apiData={useGetOnePlusMobilePhonesQuery} />;
}

export function BlackBerryPhones() {
  return <MobilePhonePage apiData={useGetBlackberryMobilePhonesQuery} />;
}

export function MotorollaPhones() {
  return <MobilePhonePage apiData={useGetMotorollaMobilePhonesQuery} />;
}

export function NokiaPhones() {
  return <MobilePhonePage apiData={useGetNokiaMobilePhonesQuery} />;
}
