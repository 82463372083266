import * as React from "react";
import Grid from "@mui/material/Grid";
import Logo from "../Logo/Logo";
import { Box } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SwipeableTemporaryDrawer from "../Drawer/Drawer";

export default function NavBarMobile({ matches }) {
  return (
    <>
      <Grid container xs={12} style={{ paddingTop: "15px" }}>
        <Grid item xs={2}>
          <Logo />
        </Grid>
        <Grid item xs={8} style={{ border: "0px solid" }}></Grid>
        <Grid item xs={2}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "right",
              width: "100%",
            }}
          >
            <SwipeableTemporaryDrawer />
          </div>
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ paddingTop: "5px" }}>
        <Grid item xs={11}>
          <div style={{ border: "1px solid grey", height: "30px" }}></div>
        </Grid>
        <Grid item xs={1}>
          <Box
            style={{
              height: "30px",
              background: "#3665f3",
              textAlign: "center",
            }}
          >
            <div>
              <SearchOutlinedIcon style={{ color: "white" }} />
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
