import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import CategoryList from "../../../../components/CategoryCard/CategoryCard";
import ImageBanner from "../../../../components/ImageBanner/ImageBanner";
import { mobilePhoneCategories } from "../../../../data/Electronics/mobile-phones/mobilePhones";


const CategoryName = styled(Typography)({});

export function CategoryItemList(props) {
  const { categoryItems } = props;
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      spacing={0}
    >
      {categoryItems.map((category, i) => (
        <Grid item key={i}>
          <Link
            to={category.link}
            style={{
              color: " #36454F",
              textDecoration: "none",
            }}
          >
            <CategoryName>{category.name}</CategoryName>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export function ElectronicsBreadcrumbs() {
  const breadcrumbs = [
    <span style={{ fontSize: "12px" }}>Home</span>,
    <span style={{ fontSize: "12px" }}>Electronics</span>,
  ];

  return (
    <Container style={{ paddingTop: "10px", paddingBottom: "10px" }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Container>
  );
}

export default function MobilePhoneCategoriesDesktop() {
  return (
    <>
      <ImageBanner image={"images/electronics/banners/mobilephonecategory.webp"} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Box
          style={{
            height: "500px",
            width: "20%",
          }}
        >
          <Typography
            style={{
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Cell Phones & Accessories
          </Typography>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            spacing={0}
          >
            <Grid item>
              <Link
                to="/apple-devices"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Cell Phone Parts</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/mobile-phones"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Cell Phones Accessories</CategoryName>
              </Link>
            </Grid>

            <Grid item>
              <Link
                to="/cameras-and-photos"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Smart Watches</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/laptops"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Smart Watch Accessories</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/car-electronics"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Phone & Sim Cards</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/home-devices"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Smart Home Devices</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/home-devices"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Portable Audio & Headphones</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/televisions-and-home-electronics"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>Vintage Cell Phones</CategoryName>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/video-games"
                style={{
                  color: " #36454F",
                  textDecoration: "none",
                }}
              >
                <CategoryName>PDA's'</CategoryName>
              </Link>
            </Grid>
          </Grid>
        </Box>

        {/*Category Page product Category images*/}
        <Box
          style={{
            height: "500px",
            width: "80%",
          }}
        >
          <Typography
            style={{
              paddingLeft: "10px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Shop by Brand
          </Typography>
          <CategoryList data={mobilePhoneCategories} />
        </Box>
      </div>
    </>
  );
}
