export const electronics = [
  {
    id: 1,
    image: "images/electronics/deals.webp",
    alt_text: "deals",
    categoryName: "Top Electronic Deals",
    link: "#",
  },
  {
    id: 2,
    image: "images/electronics/iPhone.webp",
    alt_text: "cell phones",
    categoryName: "Cell Phones & Accessories",
    link: "/cell-phones-smart-watches-accessories",
  },
  {
    id: 3,
    image: "images/electronics/laptops.webp",
    alt_text: "laptops",
    categoryName: "Computers & Tablets",
    link: "/laptops",
  },
  {
    id: 4,
    image: "images/electronics/tv.webp",
    alt_text: "television",
    categoryName: "TV & Home Electronics",
    link: "/televisions-and-home-electronics",
  },
  {
    id: 6,
    image: "images/electronics/camera.webp",
    alt_text: "cameras",
    categoryName: "Camera & Photos",
    link: "/cameras-and-photos",
  },
  {
    id: 7,
    image: "images/electronics/games.webp",
    alt_text: "video games",
    categoryName: "Video Games",
    link: "/video-games",
  },
  {
    id: 8,
    image: "images/electronics/home devices.webp",
    alt_text: "home devices",
    categoryName: "Smart Home Devices",
    link: "/home-devices",
  },
  {
    id: 9,
    image: "images/electronics/audio.webp",
    alt_text: "portable audio",
    categoryName: "Portable Audio",
    link: "/home-devices",
  },
  {
    id: 10,
    image: "images/electronics/car_electronics.webp",
    alt_text: "car electronics",
    categoryName: "Car Electronic & GPS",
    link: "/car-electronics",
  },
  {
    id: 5,
    image: "images/electronics/apple_device.webp",
    alt_text: "accessories",
    categoryName: "Accesories",
    link: "/apple-devices",
  },
];
