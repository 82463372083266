// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { Card, CardActionArea, CardMedia } from "@mui/material";

export function ImageCard({ image_data }) {
  const images = image_data;

  return (
    <Card style={{ objectFit: "contain", border: "none" }}>
      <CardActionArea style={{ width: "auto", objectFit: "contain" }}>
        <CardMedia
          component="img"
          image={images}
          alt="green iguana"
          style={{
            objectFit: "contain",
          }}
        />
      </CardActionArea>
    </Card>
  );
}

export default function ImageCarouselMobile({ data }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={30}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        loop="true"
      >
        <SwiperSlide>
          <ImageCard image_data={data.product_image[0].image} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
