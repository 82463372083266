import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import NavBar from "../../../../components/NavBar";
import MobilePhonesPageMobile from "./MobilePhonePageMobile";
import MobilePhonesPageDesktop from "./MobilePhonePageDesktop";
import PageBreadcrumbs from "../../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../../components/Footer/Footer";
import { Container, useMediaQuery } from "@mui/material";

function MobilePhonePage(props) {
  useEffect(() => {
    document.title = "Cell Phones, Smartphones for Sale";
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { apiData } = props;

  return (
    <>
      <Container>
        <NavBar />
        <PageBreadcrumbs
          breadcrumbsitems={["Home", "Electronics", "Mobile Phones"]}
        />
      </Container>

      {matches ? (
        <MobilePhonesPageMobile apiData={apiData} matches={matches} />
      ) : (
        <MobilePhonesPageDesktop apiData={apiData} matches={matches} />
      )}
    </>
  );
}

export default React.memo(MobilePhonePage);
