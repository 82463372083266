import { ProductCategoryPages } from "../../../../CategoryPage";
import { tabletAccessories } from "../../../../data/Electronics/laptops/TabletAccessories";
import SubCategoryPages from "../../SubCategoryPages";

export default function TabletAccessories() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={[
        "Home",
        "Electronics",
        "Laptops & Desktop Accessories",
      ]}
      category={
        <SubCategoryPages
          data={tabletAccessories}
          title={"Shop by Category"}
          docTitle={"Tablet Accessories for sale | qualityncheap.com"}
          categoryItems={tabletAccessories}
        />
      }
    />
  );
}
