import { Container } from "@mui/material";
import React from "react";
import { LazyLoadedPage } from "../../../../components/LazyLoading/LazyLoadedPage";


function MobilePhonesPageMobile(props) {
  const { apiData } = props;
  return (
    <>
      <Container>
        <LazyLoadedPage apiData={apiData} />
      </Container>
    </>
  );
}

export default React.memo(MobilePhonesPageMobile);
