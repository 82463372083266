import { Container } from "@mui/system";

export default function AboutUs() {
  return (
    <Container>
      <div>
        <h5 style={{ fontWeight: "bold" }}>ABOUT US</h5>
        <p>
          QnC (short for "Quality and Cheap") is a Ghanaian Sales, Marketing and
          Distribution company specializing in in partnering with leading
          product manufactures to provide cheap and affordable products and
          services to our customers, transforming each purchase into a life
          changing experience. At QnC, we create pathways to connect millions of
          manufactures and buyers in more than around the country. Our platform
          empowers our customers, providing everyone the opportunity to grow and
          thrive — no matter who they are or where they are in the country. And
          the ripple effect of our work creates waves of transformation for our
          customers, our company, our communities and our planet.
        </p>
      </div>
      <div>
        <h5 style={{ fontWeight: "bold" }}>OUR MISSION</h5>
        <p>Transforming each purchase into a life changing experience.</p>
      </div>

      <div>
        <h5 style={{ fontWeight: "bold" }}>OUR VISION</h5>
        <p>
          Become the leading Sales, Marketing and Distribution company in Ghana
          by 2025
        </p>
      </div>

      <div>
        <h5 style={{ fontWeight: "bold" }}>OUR VALUES</h5>
        <ul>
          <li>Trust</li>
          <li>Excellence</li>
          <li>Loyalty</li>
          <li>Quality Service</li>
        </ul>
      </div>
      <p style={{ fontWeight: "bold" }}></p>
    </Container>
  );
}
