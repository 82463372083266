import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";

export default function ColorTabs() {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Tab
              value="one"
              label="Home"
              style={{
                textTransform: "none",
                color: "black",
              }}
            />
          </Link>
          <Link to="electronics" style={{ textDecoration: "none" }}>
            <Tab
              value="two"
              label="Electronics"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "black",
                textTransform: "none",
              }}
            />
          </Link>

          <Link to="fashion" style={{ textDecoration: "none" }}>
            <Tab
              value="three"
              label="Fashion"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
              }}
            />
          </Link>
          <Link to="health_&_beauty" style={{ textDecoration: "none" }}>
            <Tab
              value="four"
              label="Health & Beauty"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link to="home_&_garden" style={{ textDecoration: "none" }}>
            <Tab
              value="five"
              label="Home & Garden"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link to="sports" style={{ textDecoration: "none" }}>
            <Tab
              value="six"
              label="Sports"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link to="collectibles_&_arts" style={{ textDecoration: "none" }}>
            <Tab
              value="seven"
              label="Collectibles & Arts"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link to="industrial_equipment" style={{ textDecoration: "none" }}>
            <Tab
              value="eight"
              label="Industrial Equipment"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link to="auto" style={{ textDecoration: "none" }}>
            <Tab
              value="nine"
              label="Auto"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link to="deals" style={{ textDecoration: "none" }}>
            <Tab
              value="ten"
              label="Deals"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
          <Link style={{ textDecoration: "none" }}>
            <Tab
              value="eleven"
              label="Sell"
              style={{
                fontFamily: "Market Sans, Arial, sans-serif",
                color: "#000",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            />
          </Link>
        </Tabs>
      </Box>
    </Container>
  );
}
