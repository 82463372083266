import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import DropdownMenu from "./DropdownMenu";

const useStyles = makeStyles((theme) => ({
  reset: {
    background: "white",
    boxShadow: "none",
    backgroundColor: "white",
    alignItems: "center",
    height: "30px",
  },

  navText: {
    fontSize: "1px",
    fontFamily: "Market Sans, Arial, sans-serif",
  },

  root: {
    height: "100%",
    color: "blue",
    padding: 0,
    margin: 0,
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  margin: 0,
  padding: 0,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export function TopBar() {
  const classes = useStyles();
  return (
    <>
      <Container>
        <Grid container className={classes.reset} md={12} xs={12} spacing={0.5}>
          <Grid item md={2} xs={4}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Market Sans, Arial, sans-serif",
                textAlign: "center",
              }}
            >
              Hi! <Link to="/signin">Sign in</Link> or{" "}
              <Link to="/signup">Register</Link>
            </Typography>
          </Grid>
          <Grid item md={1} xs={2}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Market Sans, Arial, sans-serif",
              }}
            >
              Daily Deals
            </Typography>
          </Grid>
          <Grid item md={2} xs={0}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Market Sans, Arial, sans-serif",
              }}
            >
              Help & Contact
            </Typography>
          </Grid>
          <Grid item md={4} xs={0}>
            <Typography></Typography>
          </Grid>
          <Grid item md={1} xs={1}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Market Sans, Arial, sans-serif",
                textAlign: "center",
              }}
            >
              Sell
            </Typography>
          </Grid>
          <Grid item md={0.8} xs={1.5}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Market Sans, Arial, sans-serif",
              }}
            >
              My QnC
            </Typography>
          </Grid>
          <Grid md={0.5} xs={1}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Market Sans, Arial, sans-serif",
              }}
            >
              <ShoppingBagOutlinedIcon
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: 0,
                  height: "18px",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider style={{ border: "0.5px solid grey" }} />
    </>
  );
}

export default function NavBarDesktop() {
  return (
    <>
      <Container>
        <div>
          <Grid
            container
            md={12}
            spacing={1}
            style={{
              height: "100px",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Grid item md={0.8}>
              <Logo />
            </Grid>
            <Grid item md={1.3}>
              <DropdownMenu />
            </Grid>
            <Grid item md={7.9}>
              <Grid
                container
                md={12}
                style={{ border: "1px solid black", padding: "0" }}
              >
                <Grid item md={10}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search all items"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                  <Grid item md={2}></Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              <Button
                style={{
                  textTransform: "none",
                  background: "#3665f3",
                  color: "white",
                  height: "40px",
                  width: "100%",
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item md={1}>
              <Button style={{ textTransform: "none" }}>Advanced</Button>
            </Grid>
          </Grid>
        </div>

        <Divider style={{ border: "0.5px solid grey" }} />
      </Container>
    </>
  );
}
