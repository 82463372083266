import { ProductCategoryPages } from "../../../../CategoryPage";
import { laptopCategories } from "../../../../data/Electronics/laptops/laptopsCategories";
import CategoryPages from "../../CategoryPage";

export default function Laptops() {
  return (
    <ProductCategoryPages
      breadcrumbsitems={["Home", "Electronics", "Laptops"]}
      category={
        <CategoryPages
          bannerImage={
            "images/electronics/banners/laptops/laptop_category_banner.webp"
          }
          data={laptopCategories}
          title={"Shop by Category"}
          docTitle={"Laptops and Many More"}
          categoryItems={[
            { name: "Computer Cable & Connectors", link: "#" },
            { name: "Keyboard,  Mice & Pointers ", link: "#" },
            {
              name: "Drives,  Storage & Blank Media ",
              link: "#",
            },
          ]}
        />
      }
    />
  );
}
