import { Breadcrumbs, Container } from "@mui/material";
import { styled } from "@mui/styles";

const BreadCrumbItem = styled("span")({
  fontFamily: "Market Sans Arial sans-serif",
  fontSize: "12px",
});

export default function PageBreadcrumbs(props) {
  const { breadcrumbsitems } = props;

  const page = breadcrumbsitems.map((user, i) => (
    <BreadCrumbItem key={i}>{user}</BreadCrumbItem>
  ));

  return (
    <Container>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {page}
        </Breadcrumbs>
      </div>
    </Container>
  );
}
