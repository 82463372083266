export const tabletAccessories = [
  {
    id: 1,
    image:
      "images/electronics/tablet-accessories/tablet_cases.webp",
    alt_text: "Cases, Covers, Keyboard Folios",
    categoryName: "Cases, Covers, Keyboard Folios",
    link: "/tablet-covers-and-keyboard-folios",
  },
  {
    id: 2,
    image:
      "images/electronics/tablet-accessories/styluses.webp",
    alt_text: "Styluses",
    categoryName: "Styluses",
    link: "/styluses",
  },
  {
    id: 3,
    image:
      "images/electronics/tablet-accessories/other-accessories.webp",
    alt_text: "Other Tablet & eBooks Accs",
    categoryName: "Other Tablet & eBooks Accs",
    link: "/Other-Tablet-&-eBooks-Accs",
  },
  {
    id: 4,
    image:
      "images/electronics/tablet-accessories/screen-protectors.webp",
    alt_text: "Screen Protectors",
    categoryName: "Screen Protectors",
    link: "/Screen-Protectors",
  },
  {
    id: 5,
    image:
      "images/electronics/tablet-accessories/chargers.webp",
    alt_text: "Chargers & Sync Cables",
    categoryName: "Chargers & Sync Cables",
    link: "/Chargers-&-Sync-Cables",
  },
  {
    id: 6,
    image:
      "images/electronics/tablet-accessories/AV-cables.webp",
    alt_text: "A/V Cables & Adapters",
    categoryName: "A/V Cables & Adapters",
    link: "/AV-Cables-&-Adapters",
  },
  {
    id: 7,
    image:
      "images/electronics/tablet-accessories/keyboards.webp",
    alt_text: "Keyboards",
    categoryName: "Keyboards",
    link: "/Keyboards",
  },
  {
    id: 8,
    image:
      "images/electronics/tablet-accessories/memory-cards.webp",
    alt_text: "Memory Card & USB Adapters",
    categoryName: "Memory Card & USB Adapters",
    link: "/Memory-Card-&-USB-Adapters",
  },
  {
    id: 9,
    image:
      "images/electronics/tablet-accessories/stickers.webp",
    alt_text: "Stickers & Decals",
    categoryName: "Stickers & Decals",
    link: "/Stickers-&-Decals",
  },
  {
    id: 10,
    image:
      "images/electronics/tablet-accessories/accessory-bundles.webp",
    alt_text: "Accessory Bundles",
    categoryName: "Accessory Bundles",
    link: "/Accessory-Bundles",
  },
  {
    id: 11,
    image:
      "images/electronics/tablet-accessories/reading-lights.webp",
    alt_text: "Reading Lights",
    categoryName: "Reading Lights",
    link: "/Reading-Lights",
  },
];
