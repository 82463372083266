import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Container, useMediaQuery } from "@mui/material";
import ListProductsDesktop from "./ListProductsDesktop";
import ListProductsMobile from "./ListProductsMobile";
import NavBar from "../../../components/NavBar";
import PageBreadcrumbs from "../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";

function ListProducts(props) {
  useEffect(() => {
    document.title = "Cell Phones, Smartphones for Sale";
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { apiData, breadcrumbsitems } = props;

  return (
    <>
      <Container>
        <NavBar />
        <PageBreadcrumbs breadcrumbsitems={breadcrumbsitems} />

        {matches ? (
          <ListProductsMobile apiData={apiData} matches={matches} />
        ) : (
          <ListProductsDesktop apiData={apiData} matches={matches} />
        )}
      </Container>
    </>
  );
}

export default React.memo(ListProducts);
