import { Container } from "@mui/system";
import { Grid, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NavBar from "../../../components/NavBar";
import PageBreadcrumbs from "../../../components/Breadcrumb/Breadcrumb";
import CategoryList, {
  CategoryListMobile,
} from "../../../components/CategoryCard/CategoryCard";
import ImageBanner from "../../../components/ImageBanner/ImageBanner";

export default function CategoryPage(props) {
  const { category, breadcrumbsitems } = props;
  return (
    <Container>
      <NavBar />
      <PageBreadcrumbs breadcrumbsitems={breadcrumbsitems} />
      {category}
    </Container>
  );
}

const CategoryName = styled(Typography)({
  fontFamily: "Serif",
});

export function CategoryItemList(props) {
  const { title, categoryItems } = props;
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      spacing={0}
    >
      <h6>{title}</h6>
      {categoryItems.map((category, i) => (
        <Grid item key={i}>
          <Link
            to={category.link}
            style={{
              color: " #36454F",
              textDecoration: "none",
            }}
          >
            <CategoryName>{category.name}</CategoryName>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export function ProductCategoryPage(props) {
  const { category, breadcrumbsitems } = props;
  return (
    <Container>
      <NavBar />
      <Container>
        <PageBreadcrumbs breadcrumbsitems={breadcrumbsitems} />
      </Container>
      {category}
    </Container>
  );
}

export function ProductCategoryPageMobile(props) {
  const { data, title } = props;
  return (
    <>
      <Container>
        <h3>{title}</h3>
        <CategoryListMobile data={data} />
      </Container>
    </>
  );
}

export function ProductCategoryPageDesktop(props) {
  const { categoryItems, bannerImage, data, title } = props;
  return (
    <>
      <Container>
        <div>
          <ImageBanner image={bannerImage} />
        </div>
        <Grid container>
          <Grid
            item
            md={2.1}
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            <CategoryItemList
              title={"Shop by Category"}
              categoryItems={categoryItems}
            />
          </Grid>
          <Grid item md={9.9} style={{ paddingTop: "20px" }}>
            <h3>{title}</h3>
            <CategoryList data={data} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
