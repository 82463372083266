import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorPageMobile from "../../pages/Error/404PageMobile";
import ErrorPage from "../../pages/Error/ErrorPage";
import LazyLoadedImageCard from "../ImageCard/LazyLoadedImageCard";
import CircularIndeterminate from "../Progress/Progress";

function LazyLoadedItems(props) {
  const { data } = props;

  return (
    <>
      {data.map((item) => {
        return <LazyLoadedImageCard key={item.id} data={item} />;
      })}
      <Container style={{ paddingTop: "20px" }}>
        <Grid container alignContent={"center"}>
          <Grid
            item
            md={12}
            xs={12}
            justifyContent={"start"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Grid>
        </Grid>
      </Container>
    </>
  );
}

export function LazyLoadedPage(props) {
  const { apiData } = props;
  const { data, isLoading, isSuccess, isError } = apiData({
    refetchOnMountOrArgChange: true,
  });

  let userData;

  if (isLoading) {
    userData = (
      <Container>
        <span><CircularIndeterminate></CircularIndeterminate></span>
      </Container>
    );
  } else if (isSuccess) {
    if (data.length === 0) {
      userData = (
        <Container style={{ paddingTop: "10px" }}>
          <h3>No Product Available</h3>
          <Link to="/">Back to Home</Link>
        </Container>
      );
    } else {
      userData = <LazyLoadedItems data={data} />;
    }
  } else if (isError) {
    userData = <div><ErrorPageMobile /></div>;
  }
  return (
    <>
      <Container>
        <Grid container md={12} spacing={1}>
          {userData}
        </Grid>
      </Container>
    </>
  );
}
