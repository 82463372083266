import React from "react";
import Home from "./pages/Home/index";
import { Routes, Route } from "react-router-dom";
import SignIn from "././pages/Login/SignInDesktop";
import { ProductCategoryPages } from "./CategoryPage.js";
import NoItemInCategoryPage from "./pages/ProductCategoryPages/NoItemInCategory.js";
import ProductListPage from "./pages/ProductCategoryPages/ProductListPage/ProductListPage.js";
import SingleProductPage from "./pages/ProductCategoryPages/SingleProductPage/SingleProductPage.js";
import { store } from "./store";
import { Provider } from "react-redux";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import {
  productsApi,
  useGetFilterMobilePhonesBrandsQuery,
  useGetNokiaMobilePhonesQuery,
} from "./features/apiSlice";
import AboutUs from "./pages/AboutUs/aboutUs.js";
import SignUp from "./pages/Login/SignUp";
import SingleProductPageMobile from "./pages/ProductCategoryPages/SingleProductPage/SingleProductPageMobile";
import MobilePhonePage from "./pages/ProductCategoryPages/Electronics/mobilePhones/index";
import CategoryPages from "./pages/ProductCategoryPages/CategoryPage";
import { TVandHomeAudioCategory } from "./data/Electronics/tvHomeAudio";
import Laptops from "./pages/ProductCategoryPages/Electronics/laptops/Laptops";

import DesktopsAndOthers from "./pages/ProductCategoryPages/Electronics/laptops/DesktopsAndOthers";
import LaptopAndDesktopAccessories from "./pages/ProductCategoryPages/Electronics/laptops/LaptopAndDesktopAccessories";
import TabletAccessories from "./pages/ProductCategoryPages/Electronics/laptops/TabletAccessories";
import ListProducts from "./pages/ProductCategoryPages/ListProducts";
import ComputerPartsAndComponents from "./pages/ProductCategoryPages/Electronics/laptops/ComputerPartsAndComponents";
import Monitors from "./pages/ProductCategoryPages/Electronics/laptops/Monitors";
import DellLaptops from "./pages/ProductCategoryPages/Electronics/laptops/PCLaptops/DellLaptops";

function App() {
  return (
    <Provider store={store}>
      <ApiProvider api={productsApi}>
        <div>
          <div className="content"></div>
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />

              {/* User Registration & Authentication */}
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />

              {/*Laptops Section */}
              <Route path="laptops" element={<Laptops />} />
              <Route path="acer-laptops" element={<NoItemInCategoryPage />} />
              <Route path="asus-laptops" element={<NoItemInCategoryPage />} />
              <Route path="dell-laptops" element={<DellLaptops />} />
              <Route path="google-laptops" element={<NoItemInCategoryPage />} />
              <Route path="HP-laptops" element={<NoItemInCategoryPage />} />
              <Route path="lenovo-laptops" element={<NoItemInCategoryPage />} />
              <Route path="msi-laptops" element={<NoItemInCategoryPage />} />
              <Route path="sony-laptops" element={<NoItemInCategoryPage />} />
              <Route
                path="toshiba-laptops"
                element={<NoItemInCategoryPage />}
              />

              {/*Desktop and All-in-one Section */}
              <Route
                path="pc-desktops-all-in-one-computers"
                element={<DesktopsAndOthers />}
              />
              <Route path="acer-computers" element={<NoItemInCategoryPage />} />
              <Route path="asus-computers" element={<NoItemInCategoryPage />} />
              <Route path="dell-computers" element={<NoItemInCategoryPage />} />
              <Route
                path="google-computers"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="lenovo-computers"
                element={<NoItemInCategoryPage />}
              />
              <Route path="msi-computers" element={<NoItemInCategoryPage />} />
              <Route path="sony-computers" element={<NoItemInCategoryPage />} />
              <Route
                path="toshiba-computers"
                element={<NoItemInCategoryPage />}
              />

              <Route
                path="laptops-desktop-accessories"
                element={<LaptopAndDesktopAccessories />}
              />
              <Route
                path="tablet-accessories"
                element={<TabletAccessories />}
              />

              <Route
                path="laptops-desktop-accessories"
                element={
                  <MobilePhonePage apiData={useGetNokiaMobilePhonesQuery} />
                }
              />
              <Route
                path="tablet-accessories"
                element={
                  <MobilePhonePage apiData={useGetNokiaMobilePhonesQuery} />
                }
              />

              {/*Printers Section */}
              <Route path="HP-Printers" element={<NoItemInCategoryPage />} />
              <Route path="Epson-Printers" element={<NoItemInCategoryPage />} />
              <Route
                path="Brother-Printers"
                element={<NoItemInCategoryPage />}
              />
              <Route path="Canon-Printers" element={<NoItemInCategoryPage />} />
              <Route path="Zebra-Printers" element={<NoItemInCategoryPage />} />
              <Route path="Dell-Printers" element={<NoItemInCategoryPage />} />
              <Route
                path="Lexmark-Printers"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Samsung-Printers"
                element={<NoItemInCategoryPage />}
              />
              <Route path="Xerox-Printers" element={<NoItemInCategoryPage />} />
              <Route path="Kodak-Printers" element={<NoItemInCategoryPage />} />
              <Route path="Rollo-Printers" element={<NoItemInCategoryPage />} />
              <Route path="Dymo-Printers" element={<NoItemInCategoryPage />} />

              {/*Computer Components and Parts Section */}
              <Route
                path="computer-components-and-parts"
                element={<ComputerPartsAndComponents />}
              />
              <Route
                path="Graphic-Video-Cards"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Laptop-Replacement-Parts"
                element={<NoItemInCategoryPage />}
              />
              <Route path="Motherboards" element={<NoItemInCategoryPage />} />
              <Route
                path="CPUs-and-Processors"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Computer-Memory"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Fans-Heat-Sinks-&-Cooling"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Interface-Add-on-Cards"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Computer-Cases-&-Accessories"
                element={<NoItemInCategoryPage />}
              />
              <Route path="Power-Supplies" element={<NoItemInCategoryPage />} />
              <Route
                path="Other-Computer-Components"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Motherboard-&-CPU-Combos"
                element={<NoItemInCategoryPage />}
              />
              <Route
                path="Video-Capture-&-TV-Tuner-Cards"
                element={<NoItemInCategoryPage />}
              />

              {/* Monitors Section */}
              <Route path="Monitors" element={<Monitors />} />

              {/*TV Audio & Home Elecronics Urls */}
              <Route
                path="televisions-and-home-electronics"
                element={
                  <ProductCategoryPages
                    breadcrumbsitems={[
                      "Home",
                      "Electronics",
                      "TV, Video & Home Audio",
                    ]}
                    category={
                      <CategoryPages
                        bannerImage={
                          "images/electronics/banners/laptops/laptop_category_banner.webp"
                        }
                        data={TVandHomeAudioCategory}
                        title={"Shop by Category"}
                        docTitle={"Laptops and Many More"}
                        categoryItems={[
                          { name: "Computer Cable & Connectors", link: "#" },
                          { name: "Keyboard,  Mice & Pointers ", link: "#" },
                          {
                            name: "Drives,  Storage & Blank Media ",
                            link: "#",
                          },
                        ]}
                      />
                    }
                  />
                }
              />

              {/*Fashion Urls */}
              <Route
                path="fashion"
                element={<NoItemInCategoryPage />}
                // <ProductCategoryPages
                //   breadcrumbsitems={["Home", "Fashion"]}
                //   category={
                //     <CategoryPages
                //       bannerImage={
                //         "images/electronics/banners/laptops/laptop_category_banner.webp"
                //       }
                //       data={laptopCategories}
                //       title={"Shop by Category"}
                //       docTitle={"Laptops and Many More"}
                //       categoryItems={[
                //         { name: "Computer Cable & Connectors", link: "#" },
                //         { name: "Keyboard,  Mice & Pointers ", link: "#" },
                //         {
                //           name: "Drives,  Storage & Blank Media ",
                //           link: "#",
                //         },
                //       ]}
                //     />
                //   }
                // />
              />

              <Route path="apple-devices" element={<NoItemInCategoryPage />} />

              <Route path="home_&_garden" element={<NoItemInCategoryPage />} />

              <Route path="categorypage" element={<ProductListPage />} />
              <Route
                path="singleproduct/:product_id"
                element={<SingleProductPage />}
              />
              <Route path="aboutus" element={<AboutUs />} />

              <Route
                path="testing"
                element={
                  <ListProducts
                    apiData={useGetFilterMobilePhonesBrandsQuery}
                    breadcrumbsitems={["Home", "Mobile Phone"]}
                  />
                }
              />
              <Route path="mobile" element={<MobilePhonePage />} />
              <Route
                path="/testlink/:product_id"
                element={<SingleProductPageMobile />}
              />
            </Routes>
          </div>
        </div>
      </ApiProvider>
    </Provider>
  );
}

export default App;
