import React from "react";
import { LazyLoadedPage } from "../../../components/LazyLoading/LazyLoadedPage";

function ListProductsDesktop(props) {
  const { apiData } = props;
  return (
    <>
      <LazyLoadedPage apiData={apiData} />
    </>
  );
}

export default React.memo(ListProductsDesktop);
