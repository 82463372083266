import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import NavBar from "../../../components/NavBar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import PageBreadcrumbs from "../../../components/Breadcrumb/Breadcrumb";


const Card = ({ data }) => {
  return (
    <Grid item>
      <div>
        {data.product_image.map((data) => (
          <img
            key={data.id}
            src={data.image}
            alt={data.alt}
            style={{
              height: "225px",
              maxWidth: "100%",
            }}
          ></img>
        ))}
      </div>
      <Link
        to={`/singleproduct/${data.id}`}
        style={{
          textTransform: "none",
          textDecoration: "none",
        }}
      >
        <Button
          style={{
            textTransform: "none",
            textAlign: "left",
            padding: "0",
          }}
        >
          <Typography
            style={{
              overflowWrap: "break-word",
              inlineSize: "250px",
            }}
          >
            {data.title}
          </Typography>
        </Button>
      </Link>

      <Typography
        style={{
          paddingTop: "5px",
          color: "#708090",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {data.condition}
      </Typography>
      <Typography
        style={{
          paddingTop: "5px",
          fontWeight: "bold",
          fontSize: "15px",
        }}
      >
        GHS {data.cash_price}
      </Typography>
      <Typography
        style={{
          paddingTop: "5px",
          fontSize: "12px",
          color: "#00008B",
          fontWeight: "bold",
        }}
      >
        Pay Later: GHS {data.loan_price}
      </Typography>
    </Grid>
  );
};

export function ProductData({ product_data }) {
  const { data, isLoading, isSuccess, isError, error } = product_data({
    refetchOnMountOrArgChange: true,
  });

  let userData;

  if (isLoading) {
    userData = <span>Loading...</span>;
  } else if (isSuccess) {
    userData = data.map((res) => {
      return <Card key={res.id} data={res} />;
    });
    console.log(data);
  } else if (isError) {
    userData = <div>{error}</div>;
  }
  return (
    <Grid container md={12} spacing={1}>
      {userData}
    </Grid>
  );
}

function ProductListPage({ product_data }) {
  return (
    <>
      <NavBar />
      <PageBreadcrumbs
        breadcrumbsitems={["Home", "Electronics", "Mobile Phones"]}
  />
      <Container style={{ paddingTop: "10px" }}>
        <ProductData product_data={product_data} />
      </Container>
      <Container style={{ textAlign: "center" }}></Container>
    </>
  );
}

export default React.memo(ProductListPage);

