import React from "react";
import { Container, Grid } from "@mui/material";
import NavBar from "../../../components/NavBar";
import Button from "@mui/material/Button";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import {
  useGetMobilePhonesQuery,
  useGetSingleProductQuery,
} from "../../../features/apiSlice";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { ImageCard } from "../../../components/Carousel/ImageCarouselMobile";
import { useParams } from "react-router-dom";
import { PopupDesktop } from "../../../components/Popup/Popup";
import WhatsappButton from "../../../components/ReactWhatsapp/ReactWhatsapp";
import CircularIndeterminate from "../../../components/Progress/Progress";

export function ImageCarouselDesktop({ data }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={30}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        loop="true"
      >
        <SwiperSlide>
          <ImageCard image_data={data.product_image[0].image} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
//refetchOnMountOrArgChange: true

export default function SingleProductPageDesktop() {
  const params = useParams();

  const { data, isLoading, isSuccess, isError, error } =
    //useGetMobilePhonesQuery(params.product_id);
    useGetSingleProductQuery(params.product_id);

  //console.log(useGetMobilePhonesQuery(params.product_id));

  if (isLoading) {
    return (
      <>
        <Container>
          <div>
            <NavBar />
          </div>
          <Container>
            <div>
            <CircularIndeterminate />
            </div>
          </Container>
        </Container>
      </>
    );
  } else if (isSuccess) {
    return (
      <>
        <Container>
          <div>
            <NavBar />
          </div>
          <Container>
            <div style={{ paddingTop: "10px" }}>
              <Grid container md={12}>
                <Grid item md={2}></Grid>
                <Grid
                  item
                  md={7}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <ImageCarouselDesktop data={data} />
                  </div>
                </Grid>

                <Grid item md={3}>
                  <div>
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <h5>GHS {data.cash_price}</h5>
                      </Grid>
                      <Grid item md={12}>
                        <p style={{ color: " #7CB9E8" }}>
                          Pay Later: GHS GHS {data.loan_price}
                        </p>
                      </Grid>
                    </Grid>
                    <Container>
                      <Grid container md={12}>
                        <Grid item md={12}>
                          <div style={{ textAlign: "center" }}>
                            <PopupDesktop />
                          </div>
                        </Grid>
                        <Grid item md={12} style={{ paddingTop: "10px" }}>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              style={{
                                width: "100%",
                                height: "30px",
                                textTransform: "none",
                              }}
                              variant="contained"
                            >
                              <WifiCalling3Icon style={{ margin: "5px" }} />
                              0207597903
                            </Button>
                          </div>
                        </Grid>
                        <Grid item md={12} style={{ paddingTop: "10px" }}>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              style={{
                                width: "100%",
                                height: "30px",
                                textTransform: "none",
                              }}
                              variant="contained"
                            >
                              <WhatsappButton />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>

                  <Grid container style={{ paddingTop: "14px", margin: "8px" }}>
                    <Grid item>
                      <div>
                        <h6
                          style={{
                            fontSize: "12px",
                            wordWrap: "breakWord",
                            width: "90%",
                          }}
                        >
                          Order & Recieve your item within 3 hours with our
                          speed buy option
                        </h6>
                        <p style={{ fontSize: "12px" }}>
                          1. Browse the item(s) of your choice <br />
                          2. Call or Whatsapp us to confirm your order
                          <br />
                          3. Recieve your order within 3hrs
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Container>
        <Container style={{ paddingTop: "10px" }}>
          <Grid container md={12}>
            <Grid item md={12}>
              <h5>{data.title}</h5>
            </Grid>
          </Grid>
        </Container>
        <Container style={{ paddingTop: "19px" }}>
          <Grid container md={12}>
            <Grid container md={12}>
              <Grid item md={4}>
                <h6>Model</h6>
                <p>{data.product_brand}</p>
              </Grid>
              <Grid item md={4}>
                <h6>Condition</h6>
                <p>{data.condition}</p>
              </Grid>
              <Grid item md={4}>
                <h6>Item #</h6>
                <p>{data.id}</p>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container style={{ paddingTop: "10px" }}>
          <Grid container md={12}>
            <Grid item md={12} style={{ marginTop: "10px" }}>
              <h5>Description</h5>
            </Grid>
          </Grid>
          <Grid container>
            {data.product_specification.map((specification) => (
              <Grid item md={4}>
                <p>
                  <em style={{ color: "#707070", fontWeight: "bold" }}>
                    {specification.specification}{" "}
                  </em>
                  : {specification.value}{" "}
                </p>
              </Grid>
            ))}
          </Grid>
        </Container>
      </>
    );
  }
}
