import { Container } from "@mui/system";
import NavBar from "../../components/NavBar";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";

export default function NoItemInCategoryPage() {
  return (
    <Container>
      <NavBar />
      <Container>
        <Divider style={{ border: "0.1px solid grey" }} />
      </Container>
      <Container style={{ paddingTop: "10px" }}>
        <h3>No Product Available</h3>
        <Link to="/">Back to Home</Link>
      </Container>
    </Container>
  );
}
