import Grid from "@mui/material/Grid";

export default function ImageBanner(props) {
  const { image } = props;
  return (
    <Grid container md={12}>
      <Grid item md={12}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <img
            src={image}
            alt="electronic banner"
            style={{ height: "180px", width: "inherit" }}
          ></img>
        </div>
      </Grid>
    </Grid>
  );
}
