export const iPadModels = [
  {
    id: 1,
    image: "images/electronics/ipads/models/ipad-pro-1st-gen.webp",
    alt_text: "Apple iPad Pro",
    categoryName: "Apple iPad Pro (1st Generation)",
    link: "/Apple-iPad-Pro-1st-Generation",
  },
  {
    id: 2,
    image: "images/electronics/ipads/models/ipad-pro-5th-gen.webp",
    alt_text: "Apple iPad Pro",
    categoryName: "Apple iPad Pro (5th Generation)",
    link: "/Apple-iPad-Pro-5th-Generation",
  },
  {
    id: 3,
    image: "images/electronics/ipads/models/ipad-air-4th-gen.webp",
    alt_text: "Apple iPad Pro",
    categoryName: "Apple iPad Pro (4th Generation)",
    link: "/Apple-iPad-Pro-4th-Generation",
  },
  {
    id: 4,
    image: "images/electronics/ipads/models/ipad-pro-3rd-gen.webp",
    alt_text: "Apple iPad Pro",
    categoryName: "Apple iPad Pro (3rd Generation)",
    link: "/Apple-iPad-Pro-3rd-Generation",
  },
  {
    id: 5,
    image: "images/electronics/ipads/models/ipad-air-2.webp",
    alt_text: "Apple iPad Air 2",
    categoryName: "Apple iPad Air 2",
    link: "/Apple-iPad-Air-2",
  },
  {
    id: 6,
    image: "images/electronics/ipads/models/ipad-9th-gen.webp",
    alt_text: "Apple iPad 9th Gen",
    categoryName: "Apple iPad (9th Generation)",
    link: "/Apple-iPad-9th-Generation",
  },
  {
    id: 7,
    image: "images/electronics/ipads/models/ipad-air-5th-gen.webp",
    alt_text: "Apple iPad Air",
    categoryName: "Apple iPad Air (5th Generation)",
    link: "/Apple-iPad-Air-5th-Generation",
  },
  {
    id: 8,
    image: "images/electronics/ipads/models/ipad-pro-4th-gen.webp",
    alt_text: "Apple iPad Pro",
    categoryName: "Apple iPad Pro (4th Generation)",
    link: "/Apple-iPad-Pro-4th-Generation",
  },
  {
    id: 9,
    image: "images/electronics/ipads/models/ipad-mini-4.webp",
    alt_text: "Apple iPad mini  4",
    categoryName: "Apple iPad mini 4",
    link: "/Apple-iPad-mini-4",
  },
  {
    id: 10,
    image: "images/electronics/ipads/models/ipad-mini-5th-gen.webp",
    alt_text: "Apple iPad mini",
    categoryName: "Apple iPad mini (5th Generation)",
    link: "/Apple-iPad-mini-5th-Generation",
  },
  {
    id: 11,
    image: "images/electronics/ipads/models/ipad-pro-2nd-gen.webp",
    alt_text: "Apple iPad Pro",
    categoryName: "Apple iPad Pro (2nd Generation)",
    link: "/Apple-iPad-Pro-2nd-Generation",
  },
];
