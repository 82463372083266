import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import {
  ProductSubCategoryPageDesktop,
  ProductSubCategoryPageMobile,
} from "./SubcategoryPages";

export default function SubCategoryPages(props) {
  const { docTitle, categoryItems, data, title } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    document.title = docTitle;
  }, []);
  return (
    <>
      {matches ? (
        <ProductSubCategoryPageMobile
          data={data}
          title={title}
          matches={matches}
        />
      ) : (
        <ProductSubCategoryPageDesktop
          data={data}
          title={title}
          categoryItems={categoryItems}
          matches={matches}
        />
      )}
    </>
  );
}
