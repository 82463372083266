import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import CableOutlinedIcon from "@mui/icons-material/CableOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import CarRepairOutlinedIcon from "@mui/icons-material/CarRepairOutlined";
import { Link } from "react-router-dom";

export default function DropDownLists() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="category-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Categories" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link
            to="/electronics"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CableOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Electronics" />
            </ListItemButton>
          </Link>
          <Link
            to="/fashion"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DiamondOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Fashion" />
            </ListItemButton>
          </Link>
          <Link
            to="/health_&_beauty"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <SpaOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Health & Beauty" />
            </ListItemButton>
          </Link>
          <Link
            to="/home_&_garden"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ChairOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Home & Garden" />
            </ListItemButton>
          </Link>
          <Link
            to="/sports"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <FitnessCenterOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Sports" />
            </ListItemButton>
          </Link>
          <Link
            to="/collectibles_&_arts"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <BrushOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Collectibles & Arts" />
            </ListItemButton>
          </Link>
          <Link
            to="/industrial_equipment"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ConstructionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Industrial Equipment" />
            </ListItemButton>
          </Link>
          <Link
            to="/auto"
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CarRepairOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Auto" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    </List>
  );
}
