import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import SingleProductPageDesktop from "./SingleProductPageDesktop";
import SingleProductPageMobile from "./SingleProductPageMobile";

export default function SingleProductPage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {matches ? (
        <SingleProductPageMobile matches={matches} />
      ) : (
        <SingleProductPageDesktop matches={matches} />
      )}
    </>
  );
}
