export const computerParts = [
  {
    id: 1,
    image: "images/electronics/computer-components-and-parts/Graphic Cards.webp",
    alt_text: "Graphic/Video Cards",
    categoryName: "Graphic/Video Cards",
    link: "/Graphic-Video-Cards",
  },
  {
    id: 2,
    image: "images/electronics/computer-components-and-parts/Parts.webp",
    alt_text: "Laptop Replacement Parts",
    categoryName: "Laptop Replacement Parts",
    link: "/Laptop-Replacement-Parts",
  },
  {
    id: 3,
    image: "images/electronics/computer-components-and-parts/Motherboards.webp",
    alt_text: "Motherboards",
    categoryName: "Motherboards",
    link: "/Motherboards",
  },
  {
    id: 4,
    image: "images/electronics/computer-components-and-parts/CPU's.webp",
    alt_text: "CPUs/Processors",
    categoryName: "CPUs/Processors",
    link: "/CPUs-and-Processors",
  },
  {
    id: 5,
    image: "images/electronics/computer-components-and-parts/RAM.webp",
    alt_text: "Memory(RAM)",
    categoryName: "Memory(RAM)",
    link: "/Computer-Memory",
  },
  {
    id: 6,
    image: "images/electronics/computer-components-and-parts/Fans.webp",
    alt_text: "Fans, Heat Sinks & Cooling",
    categoryName: "Fans, Heat Sinks & Cooling",
    link: "/Fans-Heat-Sinks-&-Cooling",
  },
  {
    id: 7,
    image: "images/electronics/computer-components-and-parts/Interface.webp",
    alt_text: "Interface/Add-on Cards",
    categoryName: "Interface/Add-on Cards",
    link: "/Interface-Add-on-Cards",
  },
  {
    id: 8,
    image: "images/electronics/computer-components-and-parts/Cases.webp",
    alt_text: "Computer Cases & Accessories",
    categoryName: "Computer Cases & Accessories",
    link: "/Computer-Cases-&-Accessories",
  },
  {
    id: 9,
    image: "images/electronics/computer-components-and-parts/Power.webp",
    alt_text: "Power Supplies",
    categoryName: "Power Supplies",
    link: "/Power-Supplies",
  },
  {
    id: 10,
    image: "images/electronics/computer-components-and-parts/Others.webp",
    alt_text: "Other Components",
    categoryName: "Other Components",
    link: "/Other-Computer-Components",
  },
  {
    id: 11,
    image: "images/electronics/computer-components-and-parts/Combos.webp",
    alt_text: "Motherboard & CPU Combos",
    categoryName: "Motherboard & CPU Combos",
    link: "/Motherboard-&-CPU-Combos",
  },
  {
    id: 12,
    image: "images/electronics/computer-components-and-parts/Video.webp",
    alt_text: "Video Capture & TV Tuner Cards",
    categoryName: "Video Capture & TV Tuner Cards",
    link: "/Video-Capture-&-TV-Tuner-Cards",
  },
  
];
