import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";

export default function DropdownMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          textTransform: "none",
          lineHeight: "102%",
          marginRight: "20%",
        }}
      >
        Shop by Category
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        spacing={0}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div>
          <Link
          to="/cell-phones-smart-watches-accessories"
            style={{
              textTranform: "none",
              color: "black",
              textDecoration: "none",
            }}
          >
            <MenuItem onClick={handleClose}>Phones</MenuItem>
          </Link>
          <Link
          to="/laptops"
            style={{
              textTranform: "none",
              color: "black",
              textDecoration: "none",
            }}
          >
            <MenuItem onClick={handleClose}>Laptops</MenuItem>
          </Link>
          <Link
          to="/televisions-and-home-electronics"
            style={{
              textTranform: "none",
              color: "black",
              textDecoration: "none",
            }}
          >
            <MenuItem onClick={handleClose}>Televisions</MenuItem>
          </Link>
          <Link
          to="/video-games"
            style={{
              textTranform: "none",
              color: "black",
              textDecoration: "none",
            }}
          >
            <MenuItem onClick={handleClose}>Video Games</MenuItem>
          </Link>
          <Link
          to="/home-devices"
            style={{
              textTranform: "none",
              color: "black",
              textDecoration: "none",
            }}
          >
            <MenuItem onClick={handleClose}>Headsets & Audio</MenuItem>
          </Link>
        </div>
      </Menu>
    </div>
  );
}
