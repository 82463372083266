import ReactWhatsapp from "react-whatsapp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function WhatsappButton() {
  return (
    <ReactWhatsapp
      number="233207597903"
      message="Welcome to Quality n Cheap. How can we support you?"
      style={{
        width: "inherit",
        border: "0",
        background: "none",
        color: "white",
      }}
    >
      <WhatsAppIcon />
      Click to chat
    </ReactWhatsapp>
  );
}
